import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Pagination from "react-js-pagination";
import { Table, Row, Col, Button, Input, CardBody, Label } from "reactstrap";
import _ from "lodash";
import { appConfig } from "./../../../configs";
import Loader from "../../../Components/Common/Loader";
import Select from "react-select";

export const usePaginationTable = (initialState = {}) => {
  const [state, setState] = useState({
    total_items: 0,
    data: [],
    ...appConfig.default_pagination_state,
    selectedRows: [],
    loader: false,
    order: "",
    orderby: "",
    ...initialState,
  });

  const changeState = (field, value) => {
    setState((previous) => {
      return {
        ...previous,
        [field]: value,
      };
    });
  };

  // Per Page & Page Setting
  const changePerPage = useCallback(
    (value) => {
      changeState("rowsPerPage", value);
      changeState("page", 0);
    },
    [changeState]
  );

  const changeActivePage = useCallback(
    (value) => {
      changeState("page", value);
    },
    [changeState]
  );

  useEffect(() => {
    if (state.data.length === 0 && state.page !== 0) {
      changeState("page", 0);
    }
  }, [state.data.length, state.page, changeState]);

  const onCheckBoxSelect = useCallback(
    (ids) => {
      changeState("selectedRows", ids);
    },
    [changeState]
  );

  const changeOrder = useCallback(
    (orderby, order) => {
      setState((previous) => ({
        ...previous,
        orderby,
        order,
      }));
    },
    [setState]
  );

  return {
    state,
    setState,
    changeState,
    changePerPage,
    changeActivePage,
    changeOrder,
    onCheckBoxSelect,
  };
};

export default function PaginationTable({
  // Table Header & Body Setting
  header,
  rows,
  totalItems,
  selectedCheckboxes,
  // Sorting Setting
  enableOrder,
  orderBy,
  order,
  changeOrder,

  // Checkbox Column & Filter Section(children) Setting
  children,
  checkboxColumn,
  selectedRows,
  onCheckBoxSelect,
  emptyTableImg = null,

  // Per Page & Page Setting
  pageSizeArr,
  perPage,
  changePerPage,
  activePage,
  changeActivePage,
  isLoader = false,
  isModalTrue = false,
  selectAllCheckbox,

  footerVisibility,
}) {
  const _onChangeSelect = (e) => {
    if (e.currentTarget.getAttribute("data-all")) {
      if (e.currentTarget.checked) {
        onCheckBoxSelect(_.map(rows, "checkboxValue"));
      } else {
        onCheckBoxSelect([]);
      }
    } else {
      let tempSelected = [...selectedRows].filter(
        (currentCheckVal) => e.currentTarget.value != currentCheckVal
      );
      if (e.currentTarget.checked) {
        onCheckBoxSelect([...tempSelected, e.currentTarget.value]);
      } else {
        onCheckBoxSelect(tempSelected);
      }
    }
  };

  // Show Page
  const selectOptions = pageSizeArr.map((pageSize) => ({
    label: `Show ${pageSize}`,
    value: pageSize,
  }));

  return (
    <React.Fragment>
      <Row className="mb-3">
        {/* Table Filter Section */}
        {children ? children : null}
        <div
          className={`overflow-auto px-0 ${
            isModalTrue ? "main-modal-fix-tabel" : ""
          } table-product-class`}
          // style={{ height: "590px", overflow: "auto" }}
        >
          <Table style={{ whiteSpace: "nowrap" }}>
            <thead>
              <tr role="row" style={{ background: "#F3F3F9" }}>
                {selectAllCheckbox && (
                  <th scope="col" className="table-th">
                    {selectAllCheckbox}
                  </th>
                )}
                {checkboxColumn ? (
                  <th scope="col" className="table-th">
                    <input
                      type="checkbox"
                      className="table-checkbox cursor-pointer"
                      onChange={_onChangeSelect}
                      data-all={true}
                      checked={
                        selectedRows.length == rows.length && rows.length != 0
                      }
                    />
                  </th>
                ) : null}
                {header.map((headerItem, headerIndex) => {
                  return (
                    <th
                      key={`thead_th_${headerIndex}`}
                      scope="col"
                      // className={`table-th ${
                      //   enableOrder && headerItem?.order && `cursor-pointer`
                      // }`}
                      className={`table-th ${
                        enableOrder && headerItem?.order && `cursor-pointer`
                      } ${headerItem.classNameWidth || ""}`}
                      onClick={() => {
                        if (enableOrder && headerItem?.order) {
                          changeOrder(
                            headerItem.field,
                            order == "asc" ? "desc" : "asc"
                          );
                          // if (headerItem.field == orderBy) {
                          //   console.log('called');
                          //   changeOrder(
                          //     headerItem.field,
                          //     order == "asc" ? "desc" : "asc"
                          //   );
                          // } else {
                          //   changeOrder(headerItem.field, "asc");
                          // }
                        }
                      }}
                    >
                      {`${headerItem.title} `}
                      {enableOrder && headerItem?.order && (
                        <>
                          {headerItem.field == orderBy && (
                            <>{order != "asc" ? `▲` : `▼`}</>
                          )}
                        </>
                      )}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody style={{}}>
              {isLoader ? (
                <tr>
                  <td colSpan={header.length}>
                    <Loader />
                  </td>
                </tr>
              ) : (
                rows.map((row, rowIndex) => {
                  return (
                    <tr role="row" key={`tr_${rowIndex}`}>
                      {checkboxColumn ? (
                        <td role="cell" className="table-td">
                          <input
                            type="checkbox"
                            className="table-checkbox cursor-pointer"
                            value={row.checkboxValue}
                            onChange={_onChangeSelect}
                            checked={selectedRows.includes(row.checkboxValue)}
                          />
                        </td>
                      ) : null}
                      {row.columns.map((column, columnIndex) => {
                        return (
                          <td
                            role="cell"
                            className="table-td"
                            key={`td_${rowIndex}_${columnIndex}`}
                          >
                            {column}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })
              )}
            </tbody>
          </Table>
        </div>
        {/* Table Section */}
        {/* <div className="overflow-x-auto -mx-6">
                    <div className="inline-block min-w-full align-middle">
                        <div className="overflow-hidden ">
                            <table className="min-w-full divide-y divide-slate-100 table-fixed dark:divide-slate-700 whitespace-nowrap">
                                <thead className=" dark:bg-slate-700 !bg-[#f2f5fad9] ">
                                    <tr role="row">
                                        {checkboxColumn ? (
                                            <th scope="col" className='table-th'>
                                                <input
                                                    type="checkbox"
                                                    className="table-checkbox cursor-pointer"
                                                    onChange={_onChangeSelect}
                                                    data-all={true}
                                                    checked={selectedRows.length == rows.length && rows.length != 0}
                                                />
                                            </th>
                                        ) : null}
                                        {header.map((headerItem, headerIndex) => {
                                            return (
                                                <th
                                                    key={`thead_th_${headerIndex}`}
                                                    scope="col"
                                                    className={`table-th ${enableOrder && headerItem?.order && `cursor-pointer`}`}
                                                    onClick={() => {
                                                        if (enableOrder && headerItem?.order) {
                                                            if (headerItem.field == orderBy) {
                                                                changeOrder(headerItem.field, order == "asc" ? "desc" : "asc")
                                                            } else {
                                                                changeOrder(headerItem.field, "asc")
                                                            }
                                                        }
                                                    }}
                                                >
                                                    {`${headerItem.title} `}
                                                    {enableOrder && headerItem?.order && (
                                                        <>
                                                            {headerItem.field == orderBy && (
                                                                <>{order != "asc" ? `🔽` : `🔼`}</>
                                                            )}
                                                        </>
                                                    )}
                                                </th>
                                            )
                                        })}
                                    </tr>
                                </thead>
                                <tbody className='bg-white divide-y divide-slate-100 dark:bg-slate-800 dark:divide-slate-700'>
                                    {rows.map((row, rowIndex) => {
                                        return (
                                            <tr role="row" key={`tr_${rowIndex}`}>
                                                {checkboxColumn ? (
                                                    <td role="cell" className="table-td">
                                                        <input
                                                            type="checkbox"
                                                            className="table-checkbox cursor-pointer"
                                                            value={row.checkboxValue}
                                                            onChange={_onChangeSelect}
                                                            checked={selectedRows.includes(row.checkboxValue)}
                                                        />
                                                    </td>
                                                ) : null}
                                                {row.columns.map((column, columnIndex) => {
                                                    return (
                                                        <td role="cell" className="table-td" key={`td_${rowIndex}_${columnIndex}`}>
                                                            {column}
                                                        </td>
                                                    )
                                                })}
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                          
                        </div>
                    </div>
                </div> */}
        {rows?.length == 0 && activePage === 0 && (
          <div className="d-flex justify-content-center my-4">
            {emptyTableImg}
          </div>
        )}
        {/* Table Footer(Pagination, Per Page) */}
        {footerVisibility && (
          <div
            className={`d-flex align-items-baseline justify-content-between ${
              isModalTrue ? "footer-paginate" : ""
            }`}
            style={{ marginTop: "5px" }}
          >
            <div
            // style={{
            //   display: "flex",
            //   alignItems: "center",
            // }}
            >
              {/* <select
                  className="form-control py-2"
                  style={{ width: "100px", cursor: "pointer" }}
                  value={perPage}
                  onChange={(e) => changePerPage(Number(e.target.value))}
                >
                  {pageSizeArr.map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </select> */}
              <div>
                <Select
                  value={selectOptions.find(
                    (option) => option.value === perPage
                  )}
                  options={selectOptions}
                  onChange={(selectedOption) =>
                    changePerPage(selectedOption.value)
                  }
                />
              </div>
            </div>
            <div
              style={{
                fontSize: "14px",
                fontWeight: "400",
                letterSpacing: "0.5",
                color: "#615b5c",
                marginLeft: "50px",
              }}
              // style={{ position: "absolute", bottom: "55px", left: "613px" }}
            >
              <span
                style={{
                  fontSize: "13px",
                  fontWeight: "400",
                  letterSpacing: "0.5",
                  color: "#595959",
                  marginRight: "5px",
                }}
              >
                {" "}
                Items :
              </span>

              <span
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  letterSpacing: "0.5",
                  color: "#161515",
                }}
              >
                {totalItems}
              </span>
              {selectedCheckboxes && (
                <>
                  <span
                    style={{
                      fontSize: "13px",
                      fontWeight: "500",
                      letterSpacing: "0.5",
                      color: "#161515",
                      marginLeft: "5px",
                    }}
                  >
                    | {selectedCheckboxes && selectedCheckboxes.length}
                  </span>
                  <span
                    style={{
                      fontSize: "13px",
                      fontWeight: "400",
                      letterSpacing: "0.5",
                      color: "#595959",
                      marginLeft: "5px",
                    }}
                  >
                    {" "}
                    items selected
                  </span>
                </>
              )}
            </div>
            <div className="col-sm-auto">
              <ul className="pagination pagination-separated pagination-md justify-content-center justify-content-sm-start mb-0">
                <Pagination
                  activePage={activePage + 1}
                  activeClass=""
                  activeLinkClass="active"
                  prevPageText="Previous"
                  nextPageText="Next"
                  itemsCountPerPage={perPage}
                  totalItemsCount={totalItems}
                  pageRangeDisplayed={5}
                  onChange={(pageNumber) => changeActivePage(pageNumber - 1)}
                  linkClass="2xl:mb-0 xl:mb-0 lg:mb-0 md:mb-0 sm:mb-4 mb-4 page-link  page-item"
                />
              </ul>
            </div>
            {/* <Pagination
              totalRecord={500}
              currentPage={activePage}
              setCurrentPage={(pageNumber) => changeActivePage(pageNumber)}
              perPageData={perPage}
            /> */}
          </div>
        )}
      </Row>
    </React.Fragment>
  );
}

PaginationTable.defaultProps = {
  children: null,
  checkboxColumn: false,

  pageSizeArr: [10, 50, 100, 200],

  enableOrder: false,
  footerVisibility: true,
  selectAllCheckbox: null,
};

PaginationTable.propTypes = {
  header: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  totalItems: PropTypes.number.isRequired,

  enableOrder: PropTypes.bool,
  orderBy: PropTypes.string,
  order: PropTypes.string,
  changeOrder: PropTypes.func,

  children: PropTypes.node,

  checkboxColumn: PropTypes.bool,
  selectedRows: PropTypes.array,
  onCheckBoxSelect: PropTypes.func,

  pageSizeArr: PropTypes.array,
  perPage: PropTypes.number,
  changePerPage: PropTypes.func.isRequired,
  activePage: PropTypes.number,
  changeActivePage: PropTypes.func.isRequired,
  selectAllCheckbox: PropTypes.element,

  footerVisibility: PropTypes.bool,
};
