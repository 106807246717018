import React, { useEffect } from "react";
import { Container, Row, Col, Card, CardHeader } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import {
  usePaginationTable,
} from "../../../Components/UI/Pagination/PaginationTable";
import { API, HELPER } from "../../../services";
import { apiConfig, appConfig } from "../../../configs";
import { toaster } from "../../../services/helper";
import { useParams } from "react-router-dom";
import error400cover from "../../../assets/images/no-data-found-page.png";
import moment from "moment";

const PaymentsPage = () => {
  const { id } = useParams();

  const { state, setState, changeState, ...otherTableActionProps } =
    usePaginationTable({
      order: "",
      orderBy: "",
    });

  const paginate = (clear = false, isNewFilter = false, type = null) => {
    changeState("loader", true);
    let clearStates = {
      ...appConfig.default_pagination_state,
    };
    let filter = {
      page: clear ? clearStates.page : state.page,
      rowsPerPage: state.rowsPerPage,
      order: state.order,
      orderBy: state.orderby,
    };

    let newFilterState = { ...appConfig.default_pagination_state };

    if (clear) {
      filter = _.merge(filter, clearStates);
    } else if (isNewFilter) {
      filter = _.merge(filter, newFilterState);
    }
    //--------- Get Pending Shipment API call--------------------------------
    API.get(apiConfig.paymentDetails.replace(":id", id), filter)
      .then((res) => {
        setState({
          ...state,
          total_items: res.count,
          data: res,
          ...(clear && clearStates),
          ...(isNewFilter && newFilterState),
          loader: false,
        });
      })
      .catch((err) => {
        if (
          err.status == 400 ||
          err.status == 401 ||
          err.status == 409 ||
          err.status == 403
        ) {
          toaster.error(err.errors.message);
        } else {
          console.error(err);
        }
        setState({
          ...state,
          ...(clear && clearStates),
          ...(isNewFilter && newFilterState),
          loader: false,
        });
      });
  };

  useEffect(() => {
    paginate();
  }, []);

  const getStatusClassName = (status) => {
    switch (status) {
      case "captured":
        return "badge text-uppercase bg-success-subtle text-success";
      case "authorized":
        return "badge text-uppercase bg-info-subtle text-info";
      case "failed":
        return "badge text-uppercase bg-danger-subtle text-danger";
      case "refunded":
        return "badge text-uppercase bg-danger-subtle text-danger";
      case "created":
        return "badge text-uppercase bg-warning-subtle text-warning";
      default:
    }
  };

  // ---------------- Customer Details Array Of Object ----------------
  const getDetails = (item) => {
    const customerDetails = [
      {
        label: "Customer Email",
        value: item.email,
      },
      {
        label: "Contact No",
        value: item.contact,
      },
      {
        label: "Created Date",
        value: moment(item.created_at).format(appConfig.dateDisplayFormat),
      },
      {
        label: "Status",
        value: item.status,
      },

      {
        label: "Amount",
        value: `${item.amount / 100} ₹`,
      },
      {
        label: "Tax",
        value: item.tax,
      },
      {
        label: "Entity",
        value: item.entity,
      },
      {
        label: "Payment Method",
        value: item.method,
      },
    ];

    const errorDetails =
      item !== null
        ? [
          {
            label: "Error Source",
            value: item.error_source,
          },
          {
            label: "Error Code",
            value: item.error_code,
          },
          {
            label: "Error Reason",
            value: item.error_reason,
          },
        ]
        : null;

    if (item?.bank !== null) {
      return customerDetails.concat(
        [
          {
            label: "Bank",
            value: item?.bank,
          },
        ],
        errorDetails || []
      );
    }
    if (item?.wallet !== null) {
      return customerDetails.concat(
        [
          {
            label: "Wallet",
            value: item?.wallet,
          },
        ],
        errorDetails
      );
    }
    if (item?.card) {
      // Handle the case where item.card is null
      return customerDetails.concat(
        [
          {
            label: "Customer Name",
            value: item?.card?.name,
          },
          {
            label: "Last 4 Digit Number",
            value: item?.card?.last4,
          },
          {
            label: "Payment Type",
            value: item?.card?.type,
          },
          {
            label: "Network",
            value: item?.card?.network,
          },
        ],
        errorDetails || []
      );
    }
    return customerDetails.concat(
      [
        {
          label: "Virtual Payment Address (VPA)",
          value: item?.vpa,
        },
      ],
      errorDetails || []
    );
  };

  // Function to render the customer details (excluding Description and Error Description)
  const renderCustomerDetails = (customerDetails) => {
    return (
      <>
        <div className="details-heading">
          <span>
            Payment Details :{" "}
            <span
              style={{
                textTransform: "uppercase",
                paddingLeft: "5px",
                color: "#000000bf",
                fontSize: "19px",
              }}
            >
              {customerDetails[7] && customerDetails[7].value}
            </span>
          </span>
        </div>
        <div className="disaply-grid-shipment main-shipping-div">
          {customerDetails.map((detail, detailIndex) => (
            <React.Fragment key={detailIndex}>
              {!HELPER.isEmpty(detail.value) ? (
                <div className="shipping-main-div">
                  <div className="shipping-labels">{detail.label} :</div>
                  <div
                    style={{
                      fontSize: "15.5px",
                      fontWeight: "400",
                      color: "#000000de",
                    }}
                    className={`${detail.label === "Payment Method"
                      ? "upparcase-class-common"
                      : ""
                      } ${detail.label === "Status"
                        ? getStatusClassName(detail.value)
                        : ""
                      }`}
                  >
                    {detail.value}
                  </div>
                </div>
              ) : null}
            </React.Fragment>
          ))}
        </div>
      </>
    );
  };

  // Function to render Description and Error Description
  const renderDescriptionDetails = (item) => {
    const errorDetails =
      item !== null ? (
        <div className="main-shipping-div">
          {item.description != null ? (
            <div className="shipping-main-div " style={{ marginTop: "10px" }}>
              <div className="shipping-labels">Description :</div>
              <div
                style={{
                  fontSize: "15.5px",
                  fontWeight: "400",
                  color: "#000000de",
                }}
              >
                {item.description}
              </div>
            </div>
          ) : null}
          {item.error_description != null ? (
            <div className="shipping-main-div " style={{ marginTop: "10px" }}>
              <div className="shipping-labels">Error Description :</div>
              <div
                style={{
                  fontSize: "15.5px",
                  fontWeight: "400",
                  color: "#000000de",
                }}
              >
                {item.error_description}
              </div>
            </div>
          ) : null}
        </div>
      ) : null;

    return errorDetails;
  };

  const combinedDetails = state?.data?.items?.map(getDetails) || [];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title=" Payments Details" pageTitle="Orders Management" />
          <Row>
            {state?.data?.items && state.data.items.length === 0 && (
              <div
                style={{
                  width: "100%",
                  paddingTop: "80px",
                  textAlign: "center",
                }}
              >
                <img src={error400cover} width="550px" />
              </div>
            )}
            {state?.data?.items && state.data.items.length !== 0 && (
              <Card id="customerList">
                <CardHeader className="border-0">
                  <Row className="">
                    <Col lg={12}>
                      {combinedDetails.map((customerDetails, index) => (
                        <div className="main-shipping-details" key={index}>
                          <React.Fragment key={index}>
                            {renderCustomerDetails(customerDetails)}
                            {renderDescriptionDetails(
                              state?.data?.items[index]
                            )}
                          </React.Fragment>
                        </div>
                      ))}
                    </Col>
                  </Row>
                </CardHeader>
              </Card>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PaymentsPage;
