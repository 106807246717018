/*------------------ Common Routes ------------------*/
export const LOGIN = "/login";
export const DASHBOARD = "/dashboard";

//----------------- E-commerce Routes  ------------------
export const USERS = "/users";

//----------------- Permission Routes  ------------------
export const PERMISSION = "/permission";
export const USER_PERMISSION = "/user-permission/:id";

//----------------- Pages Starter  ------------------

export const PAGES_STARTER = "/pages-starter";

//----------------- Email Template  ------------------

export const EMAIL_TEMPLATE = "/email-template";

//----------------- Contact Us   ------------------

export const CONTACT_US = "/contact-us";

//----------------- FAQ Page ------------------

export const FAQ = "/faq";
export const EDIT_FAQ = "/edit-faq";

//----------------- Category Page ------------------

export const CATEGORY = "/categories";

//-----------------Sub Category Page ------------------

export const SUB_CATEGORY = "/sub-categories";

//-----------------Defalut Sub Category Page ------------------

export const DEFALUT_SUB_CATEGORY = "/default-sub-categories";

//----------------- Style Master Page ------------------

export const STYLE_MASTER = "/style-master";

//----------------- Style Collection Page ------------------

export const STYLE_COLLCTION = "/style-collection";

//----------------- Super Category Page ------------------
export const SUPER_CATEGORY = "/super-categories";

//----------------- Blog Page ------------------
export const BLOG = "/blog";

//----------------- Blog Category Page ------------------
export const BLOG_CATEGORY = "/blog-category";

//----------------- News Page ------------------
export const NEWS = "/news";

//----------------- Offer And Coupons Page ------------------
export const OFFERS_COUPON = "/coupon";

//----------------- Offer Page ------------------
export const OFFERS = "/offer";

//----------------- Ratting And Review Page ------------------
export const RATTING_REVIEW = "/ratting-review";

//----------------- Products Page ------------------
export const PRODUCTS = "/products";

//----------------- Products Details Page ------------------
export const PRODUCTS_DETAILS = "/products-details";

//----------------- Shipping Gate Way Details ------------------
// export const SHIPPING_GATEWAY_DETAILS = "/shipping-gateway-details";

//----------------- Pending Shipping Details ------------------
export const PENDING_SHIPPING_GATEWAY = "/pending-shipment";

//----------------- Shipping Request ------------------
export const SHIPPING_REQUEST = "/shipping-request";

//----------------- Shipping Request ------------------
// export const SHIPPING_GATEWAY = "/shipping-gateways-details";

//----------------- Shipping Request ------------------
export const SHIPPING_GATEWAY_PAGE = "/shipping-gateways-page";

//----------------- Payment Gate Way Details ------------------
export const PAYMENT_GATEWAY_DETAILS = "/payment-gateway-details";

// ------------------------Payments Details Page ----------------------------
export const PAYMENTS = "/payments-details";

//----------------- Dynamic Meny Page ------------------
export const DYNAMIC_MENU = "/dynamic-menu";

//----------------- Customer Page ------------------
export const CUSTOMER = "/customer";

//----------------- Slider Page ------------------
export const SLIDER = "/slider";

//----------------- Slider Page ------------------
export const SLIDERMASTER = "/slider-master";

//----------------- Banner Page ------------------
export const BANNER = "/banner";

//----------------- Collection Page ------------------
export const COLLECTION = "/collection";

//----------------- Testimonial Page ------------------
export const TESTIMONIAL = "/testimonial";

//----------------- Product Arrival Page ------------------
export const PRODUCT_ARRIVAL = "/product-arrival";

//----------------- Best Selling Product -------------------
export const BEST_SELLING_PRODUCT = "/bestselling-product";

//----------------- Order Page ------------------
export const ORDER = "/order";

//-------------------Pending Order Page ------------------
export const PENDING_ORDERS = "/pending-order";

//-------------------Cancle Order Page ------------------
export const CANCLE_ORDERS = "/cancle-order";

//-------------------Pass Pending Order Page ------------------
export const PAST_PENDING_ORDERS = "/past-pending-order";

//-------------------OrderDelivered Page ------------------
export const DELIVERED_ORDERS = "/delivered-order";

//-------------------Processed Order Page ------------------
export const PROCESSED_ORDERS = "/processed-order";

export const filterRoute = (route) => {
  return route.substring(1);
};
