import React, { useEffect, useState, useMemo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Input, UncontrolledTooltip } from "reactstrap";

//SimpleBar
import SimpleBar from "simplebar-react";
import navdata from "../../Layouts/LayoutMenuData";

//import images
import image2 from "../../assets/images/users/avatar-2.jpg";
import image3 from "../../assets/images/users/avatar-3.jpg";
import image5 from "../../assets/images/users/avatar-5.jpg";
import Select from "react-select";

const SearchOption = () => {
  const navData = navdata().props.children;
  const [selectedData, setselectedData] = useState(null);

  const navigate = useNavigate();
  const navigateButton = () => {
    navigate(-1);
  };
  // ------------sortOptions-----------------------------
  const _sortOptions = navData.reduce((options, item) => {
    if (item.subItems && Array.isArray(item.subItems)) {
      options.push(
        ...item.subItems.map((subItem) => ({
          label: subItem.label,
          value: subItem.link,
        }))
      );
    }
    return options;
  }, []);

  // -----------handleSearchInputChange-----------
  const handleSearchInputChange = (selectedOption) => {
    setselectedData(selectedOption.value);
    navigate(selectedOption.value);
  };

  return (
    <div
      className="
    d-flex align-items-center"
    >
      <div>
        <Button
          type="button"
          className="btn  mr-2 "
          style={{ paddingLeft: "0" }}
          onClick={navigateButton}
          id="tooltipTop78"
          color="white"
        >
          <i
            className="ri-arrow-left-circle-fill align-bottom"
            style={{ fontSize: "33px", color: "#405178" }}
          ></i>
        </Button>
        {/* <UncontrolledTooltip placement="left" target="tooltipTop78">
          Go Back
        </UncontrolledTooltip> */}
      </div>
      <form className="app-search d-none d-md-block">
        <div className="position-relative">
          {/* -------------------Search------------------------------------------------------- */}
          <div
            style={{ width: "320px", position: "relative" }}
            className="main-search-bar"
          >
            <span
              className="mdi mdi-magnify mdi mdi-magnify "
              style={{
                fontSize: "22px",
                position: "absolute",
                right: "8px",
                top: "3px",
                zIndex: "99",
                color: "gray",
                background: "#f3f3f9",
              }}
            ></span>
            <Select
              placeholder="Search..."
              options={_sortOptions}
              value={_sortOptions.find(
                (option) => option.value === selectedData
              )}
              onChange={handleSearchInputChange}
              className="search-moal-header"
              name="choices-single-default"
              id="idStatus6"
            ></Select>
          </div>
          <span
            className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none"
            id="search-close-options"
          ></span>
        </div>
        <div className="dropdown-menu dropdown-menu-lg" id="search-dropdown">
          <SimpleBar style={{ height: "320px" }}>
            <div className="dropdown-header">
              <h6 className="text-overflow text-muted mb-0 text-uppercase">
                Recent Searches
              </h6>
            </div>

            <div className="dropdown-item bg-transparent text-wrap">
              <Link
                to="/"
                className="btn btn-soft-secondary btn-sm rounded-pill"
              >
                how to setup <i className="mdi mdi-magnify ms-1"></i>
              </Link>
              <Link
                to="/"
                className="btn btn-soft-secondary btn-sm rounded-pill"
              >
                buttons <i className="mdi mdi-magnify ms-1"></i>
              </Link>
            </div>

            <div className="dropdown-header mt-2">
              <h6 className="text-overflow text-muted mb-1 text-uppercase">
                Pages
              </h6>
            </div>

            <Link to="#" className="dropdown-item notify-item">
              <i className="ri-bubble-chart-line align-middle fs-18 text-muted me-2"></i>
              <span>Analytics Dashboard</span>
            </Link>

            <Link to="#" className="dropdown-item notify-item">
              <i className="ri-lifebuoy-line align-middle fs-18 text-muted me-2"></i>
              <span>Help Center</span>
            </Link>

            <Link to="#" className="dropdown-item notify-item">
              <i className="ri-user-settings-line align-middle fs-18 text-muted me-2"></i>
              <span>My account settings</span>
            </Link>

            <div className="dropdown-header mt-2">
              <h6 className="text-overflow text-muted mb-2 text-uppercase">
                Members
              </h6>
            </div>
            <div className="notification-list">
              <Link to="#" className="dropdown-item notify-item py-2">
                <div className="d-flex">
                  <img
                    src={image2}
                    className="me-3 rounded-circle avatar-xs"
                    alt="user-pic"
                  />
                  <div className="flex-grow-1">
                    <h6 className="m-0">Angela Bernier</h6>
                    <span className="fs-11 mb-0 text-muted">Manager</span>
                  </div>
                </div>
              </Link>

              <Link to="#" className="dropdown-item notify-item py-2">
                <div className="d-flex">
                  <img
                    src={image3}
                    className="me-3 rounded-circle avatar-xs"
                    alt="user-pic"
                  />
                  <div className="flex-grow-1">
                    <h6 className="m-0">David Grasso</h6>
                    <span className="fs-11 mb-0 text-muted">Web Designer</span>
                  </div>
                </div>
              </Link>

              <Link to="#" className="dropdown-item notify-item py-2">
                <div className="d-flex">
                  <img
                    src={image5}
                    className="me-3 rounded-circle avatar-xs"
                    alt="user-pic"
                  />
                  <div className="flex-grow-1">
                    <h6 className="m-0">Mike Bunch</h6>
                    <span className="fs-11 mb-0 text-muted">
                      React Developer
                    </span>
                  </div>
                </div>
              </Link>
            </div>
          </SimpleBar>

          <div className="text-center pt-3 pb-1">
            <Link to="/pages-search-results" className="btn btn-primary btn-sm">
              View All Results <i className="ri-arrow-right-line ms-1"></i>
            </Link>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SearchOption;
