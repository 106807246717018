import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Table,
  Input,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { API } from "../../services";
import { apiConfig } from "../../configs";
import AddUserPermission from "./AddUserPermission";
import { Link, useParams } from "react-router-dom";
import { toaster } from "../../services/helper";
import Swal from "sweetalert2";
import error400cover from "../../assets/images/no-data-found-page.png";

const UserPermission = ({ userId }) => {
  const params = useParams();
  const [modal, setModal] = useState(false);
  const [alluserPermission, setAlluserPermission] = useState({});

  // ----------------Get User Api-----------------------
  const getUserPermission = useCallback(() => {
    API.get(apiConfig.getuserPermission.replace(":user_id", params.id))
      .then((res) => {
        setAlluserPermission(res.permissionData || {});
      })
      .catch((err) => {
        if (
          err.status === 400 ||
          err.status === 401 ||
          err.status === 409 ||
          err.status === 403
        ) {
          toaster.error(err.errors.message);
        } else {
          toaster.error(err.errors.message);
        }
      });
  }, [params.id]);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      getUserPermission();
    }
  }, [modal, getUserPermission]);

  useEffect(() => {
    getUserPermission();
  }, [userId]);

  // ---------Edit User Permission---------------------
  const editUserPermissions = (user_permission_id, field, newValue) => {
    const updatedPermissions = { ...alluserPermission };

    // If View checkbox is unchecked, prevent unchecking Create, Edit, or Delete
    if (field === "view" && !newValue) {
      toaster.error("View permission cannot be unchecked");
      return;
    }

    // If Create, Edit, or Delete is checked, automatically check View
    for (const key in updatedPermissions) {
      for (const item in updatedPermissions[key]) {
        const permission = updatedPermissions[key][item];
        if (field !== "view" && newValue) {
          permission.view = true;
        }
      }
    }

    let payload = {
      [field]: newValue,
    };

    API.put(
      apiConfig.editUserPermission.replace(
        ":user_permission_id",
        user_permission_id
      ),
      payload
    )
      .then((res) => {
        toaster.success("User Permission updated successfully");
        getUserPermission();
        setAlluserPermission(updatedPermissions);
      })
      .catch((err) => {
        if (
          err.status === 400 ||
          err.status === 401 ||
          err.status === 409 ||
          err.status === 403
        ) {
          toaster.error(err.errors.message);
        } else {
          toaster.error(err.errors.message);
        }
      });
  };

  //------- Delete User Permission---------------------
  const deleteUserPermission = (permission_id) => {
    Swal.fire({
      title: "Are You Sure",
      text: "Are you sure you want to remove this User Permission?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "green",
      cancelButtonColor: "red",
      cancelButtonText: "No",
      confirmButtonText: "Yes",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        API.destroy(
          apiConfig.deleteUserPermission.replace(
            ":permission_id",
            permission_id
          )
        )
          .then((res) => {
            toaster.success("Deleted Successfully");
            getUserPermission();
          })
          .catch((err) => {
            if (
              err.status === 400 ||
              err.status === 401 ||
              err.status === 409 ||
              err.status === 403
            ) {
              toaster.error(err.errors.message);
            } else {
              toaster.error(err.errors.message);
            }
          });
      }
    });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="User Permission" pageTitle="User" />
          <Row>
            <Col lg={12}>
              <Card id="permissionList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h6 className="card-title mb-0">
                          User Permission List
                        </h6>
                      </div>
                    </div>
                    <div className="col-sm-auto">
                      <div>
                        <button
                          type="button"
                          className="btn btn-success add-btn"
                          id="create-btn"
                          onClick={() => {
                            setModal(true);
                          }}
                        >
                          <i className="ri-add-line align-bottom me-1"></i> Add
                          User Permission
                        </button>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
              </Card>
            </Col>

            <Col
              lg={12}
              style={{ height: "calc(100vh - 300px)", overflow: "auto" }}
            >
              {Object.keys(alluserPermission).length === 0 && (
                <div className="d-flex align-items-center justify-content-center">
                  {
                    <img
                      src={error400cover}
                      alt="No data found"
                      width="360px"
                    />
                  }
                </div>
              )}
              {Object.keys(alluserPermission).length > 0 && (
                <div>
                  {Object.keys(alluserPermission).map((key) => (
                    <div key={key}>
                      <Card id="permissionList">
                        <CardHeader className="border-0 pb-2">
                          <Row className="g-4 align-items-center">
                            <div className="col-sm">
                              <h4>{key}</h4>
                            </div>
                          </Row>
                        </CardHeader>

                        <div className="card-body pt-0 px-2 pb-0">
                          <Table>
                            <thead>
                              <tr style={{ background: "#F3F3F9" }}>
                                <th>Permission Name</th>
                                <th>View</th>
                                <th>Create</th>
                                <th>Edit</th>
                                <th>Delete</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {Object.keys(alluserPermission[key]).map(
                                (item) => {
                                  const permission =
                                    alluserPermission[key][item];

                                  return (
                                    <tr key={item}>
                                      <td style={{ width: "350px" }}>
                                        <h6>{permission.permission_name}</h6>
                                      </td>
                                      <td>
                                        <span className="block w-full">
                                          <Input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={permission.view}
                                            onChange={(e) =>
                                              editUserPermissions(
                                                permission.user_permission_id,
                                                "view",
                                                e.target.checked
                                              )
                                            }
                                          />
                                        </span>
                                      </td>
                                      <td>
                                        <span className="block w-full">
                                          <Input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={permission.create}
                                            onChange={(e) =>
                                              editUserPermissions(
                                                permission.user_permission_id,
                                                "create",
                                                e.target.checked
                                              )
                                            }
                                          />
                                        </span>
                                      </td>
                                      <td>
                                        <span className="block w-full">
                                          <Input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={permission.edit}
                                            onChange={(e) =>
                                              editUserPermissions(
                                                permission.user_permission_id,
                                                "edit",
                                                e.target.checked
                                              )
                                            }
                                          />
                                        </span>
                                      </td>
                                      <td>
                                        <span className="block w-full">
                                          <Input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={permission.delete}
                                            onChange={(e) =>
                                              editUserPermissions(
                                                permission.user_permission_id,
                                                "delete",
                                                e.target.checked
                                              )
                                            }
                                          />
                                        </span>
                                      </td>
                                      <td>
                                        <Link
                                          to="#"
                                          className="text-danger d-inline-block remove-item-btn"
                                          onClick={() => {
                                            const customerData =
                                              permission.user_permission_id;
                                            deleteUserPermission(customerData);
                                          }}
                                        >
                                          <i className="ri-delete-bin-6-fill fs-16"></i>
                                        </Link>
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </Table>
                        </div>
                      </Card>
                    </div>
                  ))}
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
      {/* Add User permission Component */}
      {modal && (
        <AddUserPermission
          modal={modal}
          toggle={toggle}
          setModel={setModal}
          userId={params.id}
          callBack={getUserPermission}
        />
      )}
    </React.Fragment>
  );
};

export default UserPermission;
