import appConfig from "./appConfig";

const apiConfig = {
  baseURL: (
    appConfig.host +
    "/" +
    appConfig.prefix +
    "/" +
    appConfig.version +
    "/"
  ).replace(/([^:]\/)\/+/g, "$1"),

  publicURL: (appConfig.host + "/").replace(/([^:]\/)\/+/g, "$1"),

  // auth apis
  login: "login", // admin login
  user: "user", // user
  activeDeactiveUser: "user/:client_id/toggle", // Toggle User
  changePassword: "user/password", // change Password
  deleteUser: "user/:id",

  // Permission
  permission: "permission",

  // User Permission
  getuserPermission: "userpermission/:user_id",
  addUserPermission: "userpermission",
  editUserPermission: "userpermission/:user_permission_id/toggle",
  deleteUserPermission: "userpermission/:permission_id",
  getUserNotPermission: "userpermission/:user_id/not",

  //Blog
  blogCategories: "blog_categories",
  listBlogCategory: "list/blog_category", // Blog
  blog: "blog", // Blog

  superCategory: "supercategory",
  listSuperCategory: "list/super_categories", // Super Category

  category: "category",
  listCategory: "list/categories", // Category

  subCategory: "subcategory",
  listSubCategory: "list/sub_categories",
  defaultSubCategoryToggle: "subcategory/:id/toggle", // Sub Category

  //Products
  product: "product",
  listProduct: "/list/product",
  productDetails: "productdetails",

  // Product Bulk
  productBulk: "product/bulk",
  updateProductBulk: "product/bulkUpdate",

  // Product Stock
  productStock: "product/toggle_in_stock",

  //Products Assets
  productAssets: "productassets",
  assetBulk: "productassets/bulk",

  //Gateway Detail
  gatewayDetail: "gateway_detail",
  gatewayDetailAvaliableToggle: "gateway_detail/:id/toggle", // Toggle User

  //email Template
  emailTemplate: "email_template",

  //Customer
  customer: "customer",

  //FAQ
  faq: "faq",

  //Offer And Coupon
  offerAndCoupon: "offer_and_coupon",
  activeDeactiveCouponOffer: "offer_and_coupon/offer_visibility/:id",

  // offer Master

  offerMaster: "offers_master",
  updateOfferMaster: "offers_master/:id",
  offerMasterActive: "offers_master/toggle/:id",

  // News
  news: "news",

  // shipping Gateway Detail
  shippingGatewayDetail: "shipping_gateway_detail",
  pendingShipment: "orders/pending_shipment",
  ShipRockDetails: "shipping_gateway",
  OnlyOneShipping: "shipping_gateway/:id",

  // Shipping Request
  shippingRequest: "shipping_request/:id",
  getShippingRequest: "shipping_request",
  findShipping: "shipping_request/:id",
  shippingDetails: "shipping_request/shipping_details",
  shippingResponse: "shipping_request/shipping_response",

  // customer Address
  customerAddress: "customer_address",

  // dynamic Menu
  dynamicMenu: "dynamic_menu",
  dynamicMenuList: "list/dynamic_menu",
  editDynamicMenu: "/dynamic_menu/menus",
  findEditDynamicMenu: "dynamic_menu/:id",

  // Link Up

  linkUp: "link_up",
  updateLinkUp: "link_up/:id",
  multipleLinkUp: "link_up/multiple_linkups",
  deleteLinkUp: "link_up/delete",

  // Ratting and Review
  rating_review: "rating_review",
  activeDeactiveRatting: "rating_review/:id/toggle",

  // Banner
  banner: "banner",
  bannerList: "list/banner",
  bannerUpdate: "banner/:id",

  // Collection
  collection: "collection",
  collectionUpdate: "collection/:id",
  collectionSkuUpdate: "collection_skus/:id",
  collectionSku: "collection_skus",

  // Slider
  slider: "slider",
  sliderList: "list/slider_master",
  sliderUpdate: "slider/:id",

  // Slider Master
  sliderMaster: "slide_master",
  UpdatesliderMaster: "slide_master/:id",
  sliderBanner: "slide_banner/bulk",
  SlideBanner: "slide_banner",

  // Testimonial
  testimonial: "testimonial",
  testimonialUpdate: "testimonial/:id",

  // Product Arrival
  newProductArrival: "new_product_arrival",
  UpdateProductArrival: "new_product_arrival/:id",

  // Best Selling Product
  bestSellingProduct: "best_selling",
  updateBestSelling: "best_selling/:id",

  // StyleMaster
  styleMaster: "style",
  styleMasterUpdate: "style/:id",
  listStyleMaster: "list/style_master",
  listStyleType: "list/type",

  // styleCollection
  styleCollection: "style_collection",
  styleCollectionUpdate: "style_collection/:id",

  //Contact us
  contactUs: "contact_us",
  contactUsBulk: "contact_us/bulk",

  // Style Bulk
  styleBulk: "style/bulk",

  //orders
  orders: "orders",
  pendingOrder: "orders/unconfirmed_orders",
  ordersList: "list/orders",
  findOrder: "/orders/:id",
  pendingOrders: "orders/order_confirmed/:id",
  cancleOrders: "orders/order_cancel/:id",
  orderReturn: "orders_return_details",

  //  Pass Pending
  passPending: "orders/past_pending/:id",

  // payment
  paymentDetails: "payment/:id",
  // Shipping Document
  printMaterial: "new_shipping_request/material/:material",

  //  ----------- New Shipping Request ---------------
  getNewShippingRequest: "new_shipping_request",
  cancelShipping: "new_shipping_request/cancel",
};

export default apiConfig;
