import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  UncontrolledTooltip,
  Input,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import PaginationTable, {
  usePaginationTable,
} from "../../../Components/UI/Pagination/PaginationTable";
import { API } from "../../../services";
import { apiConfig, appConfig } from "../../../configs";
import useDidMountEffect from "../../../Components/Hooks/useDidMountEffect";
import { toaster } from "../../../services/helper";
import momentTimezone from "moment-timezone";
import moment from "moment";
import error400cover from "../../../assets/images/no-data-found-page.png";
import Flatpickr from "react-flatpickr";
import AddShippingRequest from "./AddShippingRequest";
import ReactButton from "../../../Components/Common/ReactButton";

const PendingShipmentPage = () => {
  const [dateRange, setDateRange] = useState([null, null]);
  const [modal, setModal] = useState(false);
  const [currentId, setCurrentId] = useState("");
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

  const toggle = useCallback(() => {
    setModal(false);
  }, [modal]);

  const COLUMNS = [
    { title: "Order No", field: "order_no", order: true },
    { title: "Order Date", field: "order_date", order: true },
    { title: "Customer Name" },
    { title: "Payment Method" },
    { title: "Order Confirmed" },
    { title: "Order Canceled" },
    { title: "Order Return" },
    { title: "Invoice Generated" },
    { title: "Order Status" },
    { title: "Payment Status" },
  ];

  const { state, setState, changeState, ...otherTableActionProps } =
    usePaginationTable({
      searchTxt: "",
      date: {
        from_date: "",
        to_date: "",
      },
      order: "",
      orderby: "",
    });

  const paginate = (clear = false, isNewFilter = false, type = null) => {
    changeState("loader", true);
    let clearStates = {
      searchTxt: "",
      date: {
        fromDate: "",
        toDate: "",
      },
      is_clear: false,
      order: "",
      orderby: "",
      ...appConfig.default_pagination_state,
    };
    let filter = {
      page: clear ? clearStates.page : state.page,
      searchTxt: clear ? clearStates.searchTxt : state.searchTxt,
      from_date:
        !clear && dateRange[0]
          ? momentTimezone
              .tz(
                dateRange[0],
                Intl.DateTimeFormat().resolvedOptions().timeZone
              )
              .format(appConfig.dateDisplayEditFormat)
          : null,
      to_date:
        !clear && dateRange[1]
          ? momentTimezone
              .tz(
                dateRange[1],
                Intl.DateTimeFormat().resolvedOptions().timeZone
              )
              .format(appConfig.dateDisplayEditFormat)
          : null,
      rowsPerPage: state.rowsPerPage,
      order: state.order,
      orderBy: state.orderby,
    };

    let newFilterState = { ...appConfig.default_pagination_state };

    if (clear) {
      filter = _.merge(filter, clearStates);
      setDateRange([null, null]);
    } else if (isNewFilter) {
      filter = _.merge(filter, newFilterState);
    }
    //--------- Get Pending Shipment API call--------------------------------
    API.get(apiConfig.pendingShipment, filter)
      .then((res) => {
        setState({
          ...state,
          total_items: parseInt(res.count),
          data: res.rows,
          ...(clear && clearStates),
          ...(isNewFilter && newFilterState),
          loader: false,
          is_clear: clear == true,
        });
      })
      .catch((err) => {
        if (
          err.status == 400 ||
          err.status == 401 ||
          err.status == 409 ||
          err.status == 403
        ) {
          toaster.error(err.errors.message);
        } else {
          console.error(err);
        }
        setState({
          ...state,
          ...(clear && clearStates),
          ...(isNewFilter && newFilterState),
          loader: false,
          is_clear: clear == true,
        });
      });
  };

  useEffect(() => {
    // Initial API call
    paginate();
  }, []);

  useDidMountEffect(() => {
    if (state.is_clear == false) {
      paginate();
    } else {
      changeState("is_clear", false);
    }
  }, [state.page, state.rowsPerPage, state.order, state.orderby]);
  // -------------Status Display-----------
  const getStatusClassName = (status) => {
    switch (status) {
      case "SUCCESS":
        return "badge text-uppercase bg-success-subtle text-success";
      case "INITIATED":
        return "badge text-uppercase bg-info-subtle text-info";
      case "REFUND":
        return "badge text-uppercase bg-danger-subtle text-danger";
      case "PENDING":
        return "badge text-uppercase bg-warning-subtle text-warning";
      case "FAILED":
        return "badge text-uppercase bg-danger-subtle text-danger";
      case "NOT_CONFIRMED":
        return "badge text-uppercase bg-danger-subtle text-danger";
      default:
    }
  };
  // -----------Order Status--------------
  const getOrderStatus = (order_status) => {
    switch (order_status) {
      case "Delivered":
        return "badge text-uppercase bg-success-subtle text-success";
      case "confirmed":
        return "badge text-uppercase bg-info-subtle text-info";
      case "pending":
        return "badge text-uppercase bg-warning-subtle text-warning";
      case "canceled":
        return "badge text-uppercase bg-danger-subtle text-danger";
      default:
    }
  };

  // ------------CustomCheckbox----------
  const CustomCheckbox = ({ id, checked, onChange }) => (
    <div className="main-input-padding-div">
      <Input
        type="checkbox"
        defaultChecked={checked}
        onChange={onChange}
        id={id}
      />
    </div>
  );
  // ---------------handleSelectAll-----------------
  const handleSelectAll = () => {
    const selectAllChecked = selectedCheckboxes.length === state.data.length;
    const updatedCheckboxes = selectAllChecked
      ? []
      : state.data.map((item) => item.order_id);
    setSelectedCheckboxes(updatedCheckboxes);
  };
  // ----------------handleCheckbox--------------
  const handleCheckbox = (item) => {
    const isChecked = selectedCheckboxes.some(
      (selectedItem) => selectedItem === item.order_id
    );

    let updatedCheckboxes;

    if (isChecked) {
      updatedCheckboxes = selectedCheckboxes.filter(
        (selectedItem) => selectedItem !== item.order_id
      );
    } else {
      updatedCheckboxes = [...selectedCheckboxes, item.order_id];
    }
    setSelectedCheckboxes(updatedCheckboxes);
  };
  // ---------------selectAllCheckbox--------------
  const selectAllCheckbox = (
    <div className="main-input-padding-div">
      <Input
        type="checkbox"
        checked={selectedCheckboxes.length === state.data.length}
        onChange={handleSelectAll}
      />
    </div>
  );
  const rows = useMemo(() => {
    return state.data.map((item) => {
      return {
        item: item,
        columns: [
          <CustomCheckbox
            id={`checkbox_${item.order_id}`}
            checked={selectedCheckboxes.some(
              (selectedItem) => selectedItem === item.order_id
            )}
            onChange={(e) => handleCheckbox(item)}
          />,
          <span>{item.order_no}</span>,
          <span>
            {moment(item.order_date).format(appConfig.dateDisplayFormat)}
          </span>,
          <span>{item.customer_name}</span>,
          <span>{item.payment_method}</span>,
          <span>
            {item.order_confirmed == true ? (
              <span className="badge text-uppercase bg-success-subtle text-success">
                True
              </span>
            ) : (
              <span className="badge text-uppercase bg-danger-subtle text-danger">
                False
              </span>
            )}
          </span>,
          <span>
            {item.order_canceled == true ? (
              <span className="badge text-uppercase bg-success-subtle text-success">
                True
              </span>
            ) : (
              <span className="badge text-uppercase bg-danger-subtle text-danger">
                False
              </span>
            )}
          </span>,
          <span>
            {item.invoice_generated == true ? (
              <span className="badge text-uppercase bg-success-subtle text-success">
                True
              </span>
            ) : (
              <span className="badge text-uppercase bg-danger-subtle text-danger">
                False
              </span>
            )}
          </span>,
          <span>
            {item.order_return == true ? (
              <span className="badge text-uppercase bg-success-subtle text-success">
                True
              </span>
            ) : (
              <span className="badge text-uppercase bg-danger-subtle text-danger">
                False
              </span>
            )}
          </span>,
          <span className={getOrderStatus(item.order_status)}>
            {item.order_status}
          </span>,
          <span className={getStatusClassName(item.payment_status)}>
            {item.payment_status}
          </span>,
        ],
      };
    });
  }, [state.data, selectedCheckboxes]);

  document.title = " Pending Shipment Page List";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title=" Pending Shipment"
            pageTitle="Shipping Management"
          />
          <Row>
            <Col lg={12}>
              <Card id="customerList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">
                          Pending Shipment List
                        </h5>
                      </div>
                    </div>
                  </Row>
                </CardHeader>

                <div className="card-body pt-0  main-link-up-modal-div">
                  <div>
                    <PaginationTable
                      header={COLUMNS}
                      rows={rows}
                      totalItems={state.total_items}
                      perPage={state.rowsPerPage}
                      activePage={state.page}
                      checkboxColumn={false}
                      selectedRows={state.selectedRows}
                      selectedCheckboxes={selectedCheckboxes}
                      selectAllCheckbox={selectAllCheckbox}
                      enableOrder={true}
                      orderBy={state.orderby}
                      order={state.order}
                      isLoader={state.loader}
                      emptyTableImg={<img src={error400cover} width="350px" />}
                      {...otherTableActionProps}
                    >
                      <div>
                        <Row
                          className="pt-3 pb-3"
                          style={{
                            borderTop: "1px dashed rgb(152 166 173 / 50%)",
                            borderBottom: "1px dashed rgb(152 166 173 / 50%)",
                          }}
                        >
                          <Col xl={3}>
                            <div>
                              <Flatpickr
                                className="form-control"
                                placeholder="Select Date Range"
                                onChange={(date) => setDateRange(date)}
                                value={dateRange}
                                options={{
                                  mode: "range",
                                  dateFormat: "Y-m-d",
                                }}
                              />
                            </div>
                          </Col>
                          <Col sm={9}>
                            <div>
                              <button
                                type="button"
                                className="btn btn-primary "
                                onClick={() => paginate(false, true)}
                              >
                                <i className="ri-search-line me-2 align-bottom"></i>
                                Search
                              </button>
                              <button
                                id="tooltipTop13"
                                type="button"
                                className="btn btn-danger  mx-2 w-10"
                                onClick={() => {
                                  paginate(true);
                                }}
                              >
                                <i className="ri-restart-line align-bottom"></i>
                                <UncontrolledTooltip
                                  placement="top"
                                  target="tooltipTop13"
                                >
                                  Reset
                                </UncontrolledTooltip>
                              </button>
                              <div className="float-end">
                                <ReactButton
                                  color="success"
                                  className="btn btn-success "
                                  type="submit"
                                  onClick={() => setModal(true)}
                                  disabled={selectedCheckboxes.length === 0}
                                >
                                  Add Request
                                </ReactButton>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </PaginationTable>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/* AddShippingRequest Modal */}
      <AddShippingRequest
        modal={modal}
        toggle={toggle}
        setModal={setModal}
        currentId={currentId}
        setCurrentId={setCurrentId}
        selectedCheckboxes={selectedCheckboxes}
        callBack={() => paginate(true)}
      />
    </React.Fragment>
  );
};

export default PendingShipmentPage;
