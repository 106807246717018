import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

//import images
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import { store } from "../../slices";
import { authSlice, handleLogout } from "../../slices/toolkit/auth";
import { ROUTES } from "../../configs";
import { useSelector } from "react-redux";
import { HELPER } from "../../services";
import ChangePassword from "../../pages/Ecommerce/EcommerceCustomers/ChangePassword";

const ProfileDropdown = () => {
  const auth = useSelector((state) => state.auth);
  const [isChangePass, setChangePass] = useState(false);

  const PassToggle = useCallback(() => {
    setChangePass(false);
  }, [isChangePass]);

  //Dropdown Toggle
  const [isProfileDropdown, setIsProfileDropdown] = useState(false);
  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown);
  };
  return (
    <React.Fragment>
      <Dropdown
        isOpen={isProfileDropdown}
        toggle={toggleProfileDropdown}
        className="ms-sm-3 header-item topbar-user"
      >
        <DropdownToggle tag="button" type="button" className="btn">
          <span className="d-flex align-items-center">
            <img
              className="rounded-circle header-profile-user"
              src={auth && auth.user && HELPER.getImageUrl(auth.user.profile)}
              alt="Header Avatar"
            />
            <span className="text-start ms-xl-2">
              <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                {auth && auth.user && auth.user.name}
              </span>
              <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">
                Founder
              </span>
            </span>
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {auth.user && (
            <h6 className="dropdown-header">Welcome to {auth.user.name}</h6>
          )}
          <DropdownItem className="p-0">
            <div
              // to={process.env.PUBLIC_URL + ROUTES.PROFILE}
              className="dropdown-item"
              onClick={() => {
                setChangePass(true);
              }}
            >
              <i className="ri-lock-password-line text-muted fs-16 align-middle me-1"></i>
              <span className="align-middle">Change Password</span>
            </div>
          </DropdownItem>
          <DropdownItem className="p-0">
            <Link
              onClick={() => store.dispatch(handleLogout())}
              className="dropdown-item"
            >
              <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{" "}
              <span className="align-middle" data-key="t-logout">
                Logout
              </span>
            </Link>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      {/* Change Password Component */}
      {isChangePass && (
        <ChangePassword
          modal={isChangePass}
          setModal={setChangePass}
          toggle={PassToggle}
        // callBack={() => paginate(true)}
        />
      )}
    </React.Fragment>
  );
};

export default ProfileDropdown;
