import _, { isArray, reject } from "lodash";
import { toast } from "react-hot-toast";
import { appConfig } from "../configs";
import noimg from "../assets/images/users/noimg.jpg";

export const isEmpty = (value) => {
  if (value == null || value === "null") {
    return true;
  }
  if (typeof value == "object") {
    return Object.keys(value).length === 0;
  }
  return (
    (isArray(value) && value.length === 0) ||
    value === undefined ||
    value === "undefined" ||
    value == null ||
    value === ""
  );
};

export const toaster = {
  error: (message, config = {}) => {
    toast.error(message);
  },
  success: (message, config = {}) => {
    toast.success(message);
  },
};

export const isAuth = () => {
  return (
    false == isEmpty(window.localStorage.getItem(appConfig.localStorage.token))
  );
};

export const showErrorToaster = (err) => {
  if (err.status == 400 || err.status == 401 || err.status == 422) {
    toaster.error(err.errors.message);
  } else {
    toaster.error(err);
  }
};

export const getImageUrl = (url) => {
  if (isEmpty(url)) {
    return noimg;
  }
  const isAbsoluteUrl = /^(?:\w+:)\/\//.test(url);
  if (isAbsoluteUrl) {
    return url;
  } else {
    return `${appConfig.host}${url}`.replace(/([^:]\/)\/+/g, "$1");
  }
};

export const imgObjToBase64 = (imgObj) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = (error) => {
      console.log("error", error);
      reject(error);
    };
    reader.readAsDataURL(imgObj);
  });
};

export const checkUrlType = (url) => {
  // Regular expressions to match common video and image file extensions
  const videoExtensions = /\.(mp4|mov|avi|wmv|flv|mkv)$/i;
  const imageExtensions = /\.(jpg|jpeg|png|gif|bmp)$/i;

  // Test if the URL matches the video or image extensions
  if (url.match(videoExtensions)) {
    return 'video';
  } else if (url.match(imageExtensions)) {
    return 'image';
  } else {
    return null;
  }
}
