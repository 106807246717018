import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  Form,
  ModalBody,
  Label,
  Input,
  Button,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import Validators from "../../Components/validations/Validator";
import Textinput from "../../Components/Common/Textinput";
import ReactButton from "../../Components/Common/ReactButton";
import { API } from "../../services";
import { apiConfig } from "../../configs";
import { toaster } from "../../services/helper";

const AddPermission = ({ modal, toggle, setModel, callback }) => {
  const [isLoader, setIsLoader] = useState(false);
  // ---------------FormState------------------
  const [formState, setFormState] = useState({
    permission_group: "",
    permission_name: "",
  });

  // -------------------Validation----------------------
  const rules = {
    permission_group: "required",
    permission_name: "required",
  };

  useEffect(() => {
    setFormState("");
  }, [modal]);
  // ------------------handleSubmit Permission------------------
  const handleSubmit = (data) => {
    setIsLoader(true);
    API.post(apiConfig.permission, data)
      .then((response) => {
        toaster.success("Permission add Success");
        setFormState("");
        setModel(false);
        callback();
      })
      .catch(() => {
        setModel(true);
      })
      .finally(() => {
        setIsLoader(false);
      });
  };
  // ---------------onChange-----------------------
  const onChange = ({ target: { value, name } }) => {
    setFormState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  return (
    // --------------------Add Permission Modal-----------------------------
    <div>
      <Modal id="showModal" isOpen={modal} toggle={toggle} centered>
        <ModalHeader className="bg-light p-3" toggle={toggle}>
          Add Permission
        </ModalHeader>
        <ModalBody>
          <div>
            <Validators formData={formState} rules={rules}>
              {({ onSubmit, errors }) => {
                return (
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      onSubmit(handleSubmit);
                    }}
                    action="#"
                  >
                    <Textinput
                      label={"Permission Group"}
                      value={formState.permission_group}
                      name="Permission_group"
                      placeholder="Enter Permission Group"
                      error={errors?.permission_group}
                      type="text"
                      onChange={onChange}
                      className="py-[10px] pl-[20px] pr-[20px] text-sm h-[37px] border w-[220px] rounded-[10px] text-[#552F62] outline-none placeholder-[#552F62] font-medium"
                    />

                    <div className="mb-3">
                      <Textinput
                        label={"Permission Name"}
                        value={formState.permission_name}
                        name="Permission_name"
                        error={errors?.permission_name}
                        type="text"
                        placeholder="Enter Permission Name"
                        onChange={onChange}
                        className="py-[10px] pl-[20px] pr-[20px] text-sm h-[37px] border w-[220px] rounded-[10px] text-[#552F62] outline-none placeholder-[#552F62] font-medium"
                      />
                    </div>

                    <div className="mt-4">
                      <ReactButton
                        loader={isLoader}
                        color="success"
                        className="btn btn-success w-100"
                        type="submit"
                      >
                        Add Permission
                      </ReactButton>
                    </div>
                  </Form>
                );
              }}
            </Validators>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AddPermission;
