import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

// Front
import LayoutReducer from "./layouts/reducer";

import authToolkit from "./toolkit/auth";
import dateRange from "./toolkit/date";

const combinedReducer = combineReducers({
  Layout: LayoutReducer,
  auth: authToolkit,
  dashboard: dateRange,
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    localStorage.removeItem(appConfig.localStorage.token);
    state = {
      layout: state.layout,
      auth: defaultState.toolkit.auth,
    };
  }
  return combinedReducer(state, action);
};

const mixReducer = persistReducer(
  { key: "root", storage, whitelist: ["layout", "auth"] },
  rootReducer
);

// Create the store
export const store = configureStore({
  reducer: mixReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// Initialize the persistence layer
export const persistor = persistStore(store);
