import React, { useEffect, useState } from "react";
import { API } from "../../../services";
import { apiConfig } from "../../../configs";
import Validators from "../../../Components/validations/Validator";
import { toaster } from "../../../services/helper";
import Textinput from "../../../Components/Common/Textinput";
import ReactButton from "../../../Components/Common/ReactButton";
import {
  CardBody,
  Col,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import "quill/dist/quill.snow.css";

const AddShippingRequest = ({
  modal,
  toggle,
  setModal,
  selectedCheckboxes,
  callBack,
}) => {
  const [isLoader, setIsLoader] = useState(false);

  //  -------------formState --------------
  const [formState, setFormState] = useState({
    length: "",
    width: "",
    height: "",
    weight: "",
  });
  const rules = {
    length: "required|numeric|min:0.51",
    width: "required|numeric|min:0.51",
    height: "required|numeric|min:0.51",
    weight: "required",
  };
  //  --------------handle onSubmit Shipping Request  --------------
  const handleAddShipping = (data) => {
    let payload = {
      ...data,
      order_ids: selectedCheckboxes,
    };
    setIsLoader(true);
    API.post(apiConfig.getNewShippingRequest, payload)
      .then((res) => {
        toaster.success("Shipping Request add successfully");
        setModal(false);
        callBack();
      })
      .catch((err) => {
        if (
          err.status == 400 ||
          err.status == 401 ||
          err.status == 409 ||
          err.status == 403
        ) {
          toaster.error(err.errors.message);
        } else if (err.status == 422) {
          // Check if 'balance' error exists
          if (err.errors.balance) {
            toaster.error(err.errors.balance);
          }
          // Check if 'message' error exists
          if (err.errors.message) {
            toaster.error(err.errors.message);
          }
          // Check if 'Address' error exists
          if (err.errors.Address) {
            toaster.error(err.errors.Address);
          }
          // Check if 'Pincode' error exists
          if (err.errors.Pincode) {
            toaster.error(err.errors.Pincode);
          }
        } else {
          toaster.error(err.errors.message);
        }
      })
      .finally(() => {
        setIsLoader(false);
      });
  };

  // -------------onChange------------------

  const onChange = ({ target: { value, name } }) => {
    const numericRegex = /^-?\d*\.?\d*$/;
    if (numericRegex.test(value) || value === "") {
      setFormState((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };
  // const onChange = ({ target: { value, name } }) => {
  //   setFormState((prev) => ({
  //     ...prev,
  //     [name]: value,
  //   }));
  // };

  useEffect(() => {
    setFormState("");
  }, [modal]);

  return (
    // -------------------Add Shipping Request Modal-------------------
    <>
      <Modal
        id="showModal"
        isOpen={modal}
        toggle={toggle}
        backdrop={"static"}
        centered
        className="modal-md"
      >
        <ModalHeader className="bg-light p-3" toggle={toggle}>
          Add Shipping Request
        </ModalHeader>

        <ModalBody>
          <Validators formData={formState} rules={rules}>
            {({ onSubmit, errors }) => {
              return (
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    onSubmit(handleAddShipping);
                  }}
                  action="#"
                >
                  <CardBody>
                    <Row>
                      <Col sm={4}>
                        <Textinput
                          label={"Enter Length"}
                          value={formState.length || ""}
                          error={errors?.length}
                          name="length"
                          placeholder="Enter Length"
                          onChange={onChange}
                        />
                      </Col>
                      <Col sm={4}>
                        <Textinput
                          label={"Enter Width"}
                          value={formState.width}
                          name="width"
                          error={errors?.width}
                          onChange={onChange}
                          placeholder="Enter Width"
                        />
                      </Col>
                      <Col sm={4}>
                        <Textinput
                          label={"Enter Height"}
                          value={formState.height}
                          name="height"
                          error={errors?.height}
                          onChange={onChange}
                          placeholder="Enter Height"
                        />
                      </Col>
                    </Row>
                    <Textinput
                      label={"Enter Weight (Gms) "}
                      value={formState.weight}
                      name="weight"
                      error={errors?.weight}
                      onChange={onChange}
                      placeholder="Enter Weight"
                    />
                    <div className="text-end  w-25 float-end">
                      <ReactButton
                        loader={isLoader}
                        color="success"
                        className="btn btn-success w-100"
                        type="submit"
                      >
                        Submit
                      </ReactButton>
                    </div>
                  </CardBody>
                </Form>
              );
            }}
          </Validators>
        </ModalBody>
      </Modal>
    </>
  );
};

export default AddShippingRequest;
