import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Container, Row, Col, Card, CardHeader } from "reactstrap";

import { Link } from "react-router-dom";

//Import Breadcrumb
import BreadCrumb from "../../Components/Common/BreadCrumb";

import PaginationTable, {
  usePaginationTable,
} from "../../Components/UI/Pagination/PaginationTable";
import { API, HELPER } from "../../services";
import { apiConfig, appConfig } from "../../configs";
import useDidMountEffect from "../../Components/Hooks/useDidMountEffect";
import AddPermission from "./AddPermission";
import error400cover from "../../assets/images/no-data-found-page.png";
import { toaster } from "../../services/helper";

const Permission = () => {
  const [modal, setModal] = useState(false);
  const [permission, setPermission] = useState([]);
  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setPermission(null);
    }
  }, [modal]);

  const COLUMNS = [{ title: "Permission Group" }, { title: "Permission Name" }];

  const { state, setState, changeState, ...otherTableActionProps } =
    usePaginationTable({
      searchTxt: "",
    });

  const paginate = (clear = false, isNewFilter = false) => {
    changeState("loader", true);

    let filter = {
      page: state.page,
      rowsPerPage: state.rowsPerPage,
    };

    let clearStates = {
      ...appConfig.default_pagination_state,
    };

    let newFilterState = { ...appConfig.default_pagination_state };

    if (clear) {
      filter = _.merge(filter, clearStates);
    } else if (isNewFilter) {
      filter = _.merge(filter, newFilterState);
    }
    // --------------Get Permission Api-----------------
    API.get(apiConfig.permission, filter)
      .then((res) => {
        setState({
          ...state,
          total_items: res.count,
          data: res.rows,
          ...(clear && clearStates),
          ...(isNewFilter && newFilterState),
          loader: false,
        });
      })
      .catch((err) => {
        if (
          err.status == 400 ||
          err.status == 401 ||
          err.status == 409 ||
          err.status == 403
        ) {
          toaster.error(err.errors.message);
        } else {
          console.error(err);
        }
        setState({
          ...state,
          ...(clear && clearStates),
          ...(isNewFilter && newFilterState),
          loader: false,
        });
      });
  };

  useEffect(() => {
    // initial api call
    paginate();
  }, []);

  useDidMountEffect(() => {
    paginate();
  }, [state.page, state.rowsPerPage]);

  const rows = useMemo(() => {
    return state.data.map((item) => {
      return {
        item: item,
        columns: [
          <span>{item.permission_group}</span>,
          <div>{item.permission_name}</div>,
        ],
      };
    });
  }, [state.data]);

  document.title = "Permission";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Permission" pageTitle="User Management" />
          <Row>
            <Col lg={12}>
              <Card id="permissionList">
                <CardHeader className="border-0 mb-1 mt-1">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">Permission List</h5>
                      </div>
                    </div>
                    <div className="col-sm-auto">
                      {/* <div>
                        <button
                          type="button"
                          className="btn btn-success add-btn"
                          id="create-btn"
                          onClick={() => {
                            setModal(true);
                          }}
                        >
                          <i className="ri-add-line align-bottom me-1"></i> Add
                          Permission
                        </button>
                      </div> */}
                    </div>
                  </Row>
                </CardHeader>
                <div className="card-body pt-0">
                  <div>
                    <PaginationTable
                      header={COLUMNS}
                      rows={rows}
                      totalItems={state.total_items}
                      perPage={state.rowsPerPage}
                      activePage={state.page}
                      checkboxColumn={false}
                      selectedRows={state.selectedRows}
                      enableOrder={false}
                      isLoader={state.loader}
                      emptyTableImg={<img src={error400cover} width="350px" />}
                      {...otherTableActionProps}
                    ></PaginationTable>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <AddPermission
        modal={modal}
        toggle={toggle}
        setModel={setModal}
        callback={() => paginate(true)}
      />
    </React.Fragment>
  );
};

export default Permission;
