import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { HELPER } from "../services";
import { ROUTES } from "../configs";
import { useSelector } from "react-redux";

const Navdata = () => {
  const history = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const auth = useSelector((state) => state?.auth?.user?.permissions);

  // All Permission_name Array Value ------------------
  const allPermissions = Object.keys(auth || {}).reduce((result, category) => {
    if (Array.isArray(auth[category])) {
      const categoryPermissions = auth[category]
        .filter((item) => item.permission_name)
        .map((item) => item.permission_name);

      result[category] = categoryPermissions;
    }
    return result;
  }, {});

  // ----- All Value Concat And Single Array Of Obejct Apply Category and Permision Array Object
  const combinedArray = Object.entries(allPermissions).reduce(
    (result, [category, permissionArray]) => {
      // Map each permission to an object with category and permission properties
      const categoryPermissions = permissionArray.map((permission) => ({
        // category,
        permission,
      }));

      // Concatenate the category permissions to the result array
      return result.concat(categoryPermissions);
    },
    []
  );

  const [menus, setMenus] = useState([
    {
      label: "Dashboard",
      isHeader: true,
    },
    {
      id: "dashboard_ecommerce",
      label: "Dashboard",
      link: ROUTES.DASHBOARD,
      icon: " ri-function-line",
      isActive: false,
      click: function (e) {
        e.preventDefault();
        changeMenu("dashboard_ecommerce");
      },
    },
    {
      id: "apps_ecommerce_6",
      label: "Users Management",
      icon: " ri-account-circle-line",
      link: "/#",
      isActive: false,
      isChildItem: true,
      click: function (e) {
        e.preventDefault();
        changeMenu("apps_ecommerce_6");
      },
      subItems: [
        {
          id: "apps_ecommerce_26",
          label: "Admins",
          link: ROUTES.USERS,
          parentId: "apps",
          isActive: false,
          permission: "user",
          click: function (e) {
            e.preventDefault();
            changeMenu("apps_ecommerce_26");
          },
        },
        {
          id: "apps_ecommerce_25",
          label: "Customer",
          link: ROUTES.CUSTOMER,
          parentId: "apps",
          isActive: false,
          permission: "customer_details",
          click: function (e) {
            e.preventDefault();
            changeMenu("apps_ecommerce_25");
          },
        },
      ],
    },
    {
      id: "apps_ecommerce_698",
      label: "Categories Management",
      icon: " ri-order-play-fill",
      link: "/#",
      isActive: false,
      isChildItem: true,
      click: function (e) {
        e.preventDefault();
        changeMenu("apps_ecommerce_698");
      },
      subItems: [
        {
          id: "apps_ecommerce_10",
          label: "Super Category",
          link: ROUTES.SUPER_CATEGORY,
          parentId: "apps",
          isActive: false,
          permission: "super_category",
          click: function (e) {
            e.preventDefault();
            changeMenu("apps_ecommerce_10");
          },
        },
        {
          id: "apps_ecommerce_11",
          label: "Category",
          link: ROUTES.CATEGORY,
          parentId: "apps",
          isActive: false,
          permission: "category",
          click: function (e) {
            e.preventDefault();
            changeMenu("apps_ecommerce_11");
          },
        },
        {
          id: "apps_ecommerce_19",
          label: "Sub Category",
          link: ROUTES.SUB_CATEGORY,
          parentId: "apps",
          isActive: false,
          permission: "subcategory",
          click: function (e) {
            e.preventDefault();
            changeMenu("apps_ecommerce_19");
          },
        },
        {
          id: "apps_ecommerce_190",
          label: "Default Sub Category",
          link: ROUTES.DEFALUT_SUB_CATEGORY,
          parentId: "apps",
          isActive: false,
          permission: "subcategory",
          click: function (e) {
            e.preventDefault();
            changeMenu("apps_ecommerce_190");
          },
        },
      ],
    },
    {
      id: "apps_ecommerce_719",
      label: "Pages Management",
      icon: " ri-pages-line",
      link: "/#",
      isActive: false,
      isChildItem: true,
      click: function (e) {
        e.preventDefault();
        changeMenu("apps_ecommerce_719");
      },
      subItems: [
        {
          id: "apps_ecommerce_29",
          label: "Banner",
          link: ROUTES.BANNER,
          parentId: "apps",
          isActive: false,
          permission: "banner",
          click: function (e) {
            e.preventDefault();
            changeMenu("apps_ecommerce_29");
          },
        },
        {
          id: "apps_ecommerce_28",
          label: "Slider",
          link: ROUTES.SLIDER,
          parentId: "apps",
          isActive: false,
          permission: "slider",
          click: function (e) {
            e.preventDefault();
            changeMenu("apps_ecommerce_28");
          },
        },

        {
          id: "apps_ecommerce_30",
          label: "Collection",
          link: ROUTES.COLLECTION,
          parentId: "apps",
          isActive: false,
          permission: "collection",
          click: function (e) {
            e.preventDefault();
            changeMenu("apps_ecommerce_30");
          },
        },
        {
          id: "apps_ecommerce_24",
          label: "Dynamic Menu",
          link: ROUTES.DYNAMIC_MENU,
          parentId: "apps",
          isActive: false,
          permission: "dynamic_menu",
          click: function (e) {
            e.preventDefault();
            changeMenu("apps_ecommerce_24");
          },
        },
      ],
    },
    {
      id: "apps_ecommerce_699",
      label: "Products Management",
      icon: "ri-product-hunt-line",
      link: "/#",
      isActive: false,
      isChildItem: true,
      click: function (e) {
        e.preventDefault();
        changeMenu("apps_ecommerce_699");
      },
      subItems: [
        {
          id: "apps_ecommerce_20",
          label: "Products",
          link: ROUTES.PRODUCTS,
          parentId: "apps",
          isActive: false,
          permission: "product",
          click: function (e) {
            e.preventDefault();
            changeMenu("apps_ecommerce_20");
          },
        },
        {
          id: "apps_ecommerce_32",
          label: "New Arrival",
          link: ROUTES.PRODUCT_ARRIVAL,
          parentId: "apps",
          isActive: false,
          permission: "new_arrival",
          click: function (e) {
            e.preventDefault();
            changeMenu("apps_ecommerce_32");
          },
        },
        {
          id: "apps_ecommerce_39",
          label: "Best Selling",
          link: ROUTES.BEST_SELLING_PRODUCT,
          parentId: "apps",
          isActive: false,
          permission: "new_arrival",
          click: function (e) {
            e.preventDefault();
            changeMenu("apps_ecommerce_39");
          },
        },
      ],
    },
    {
      id: "apps_ecommerce_716",
      label: "Orders Management",
      icon: "ri-shopping-cart-2-line",
      link: "/#",
      isActive: false,
      isChildItem: true,
      click: function (e) {
        e.preventDefault();
        changeMenu("apps_ecommerce_716");
      },
      subItems: [
        {
          id: "apps_ecommerce_734",
          label: "Pending Orders",
          link: ROUTES.PENDING_ORDERS,
          parentId: "apps",
          isActive: false,
          permission: "order",
          click: function (e) {
            e.preventDefault();
            changeMenu("apps_ecommerce_734");
          },
        },
        {
          id: "apps_ecommerce_739",
          label: "Proccessed Orders",
          link: ROUTES.PROCESSED_ORDERS,
          parentId: "apps",
          isActive: false,
          permission: "order",
          click: function (e) {
            e.preventDefault();
            changeMenu("apps_ecommerce_739");
          },
        },
        {
          id: "apps_ecommerce_738",
          label: "Past Pending Orders",
          link: ROUTES.PAST_PENDING_ORDERS,
          parentId: "apps",
          isActive: false,
          permission: "order",
          click: function (e) {
            e.preventDefault();
            changeMenu("apps_ecommerce_738");
          },
        },
        {
          id: "apps_ecommerce_754",
          label: "Delivered Orders",
          link: ROUTES.DELIVERED_ORDERS,
          parentId: "apps",
          isActive: false,
          permission: "order",
          click: function (e) {
            e.preventDefault();
            changeMenu("apps_ecommerce_754");
          },
        },
        {
          id: "apps_ecommerce_735",
          label: "Cancle Orders",
          link: ROUTES.CANCLE_ORDERS,
          parentId: "apps",
          isActive: false,
          permission: "order",
          click: function (e) {
            e.preventDefault();
            changeMenu("apps_ecommerce_735");
          },
        },
        {
          id: "apps_ecommerce_84",
          label: "Orders",
          link: ROUTES.ORDER,
          parentId: "apps",
          isActive: false,
          permission: "order",
          click: function (e) {
            e.preventDefault();
            changeMenu("apps_ecommerce_84");
          },
        },
      ],
    },
    {
      id: "apps_ecommerce_726",
      label: "Shipping Management",
      icon: " ri-truck-line",
      link: "/#",
      isActive: false,
      isChildItem: true,
      click: function (e) {
        e.preventDefault();
        changeMenu("apps_ecommerce_726");
      },
      subItems: [
        {
          id: "apps_ecommerce_736",
          label: "Pending Shipment",
          link: ROUTES.PENDING_SHIPPING_GATEWAY,
          parentId: "apps",
          isActive: false,
          permission: "shipping_request",
          click: function (e) {
            e.preventDefault();
            changeMenu("apps_ecommerce_736");
          },
        },
        {
          id: "apps_ecommerce_737",
          label: "Shipping Request",
          link: ROUTES.SHIPPING_REQUEST,
          parentId: "apps",
          isActive: false,
          permission: "shipping_request",
          click: function (e) {
            e.preventDefault();
            changeMenu("apps_ecommerce_737");
          },
        },
        // {
        //   id: "apps_ecommerce_22",
        //   label: "Shipping Details",
        //   link: ROUTES.SHIPPING_GATEWAY_DETAILS,
        //   parentId: "apps",
        //   isActive: false,
        //   permission: "shipping_gateway_details",
        //   click: function (e) {
        //     e.preventDefault();
        //     changeMenu("apps_ecommerce_22");
        //   },
        // },
        // {
        //   id: "apps_ecommerce_740",
        //   label: "Shipping Gateway",
        //   link: ROUTES.SHIPPING_GATEWAY,
        //   parentId: "apps",
        //   isActive: false,
        //   permission: "shipping_gateway",
        //   click: function (e) {
        //     e.preventDefault();
        //     changeMenu("apps_ecommerce_740");
        //   },
        // },
      ],
    },
    {
      id: "apps_ecommerce_854",
      label: "Offers & Coupons",
      icon: " ri-coupon-3-line",
      link: "/#",
      isActive: false,
      isChildItem: true,
      click: function (e) {
        e.preventDefault();
        changeMenu("apps_ecommerce_854");
      },
      subItems: [
        {
          id: "apps_ecommerce_54",
          label: "Offers Management",
          link: ROUTES.OFFERS,
          parentId: "apps",
          isActive: false,
          permission: "offers_master",
          click: function (e) {
            e.preventDefault();
            changeMenu("apps_ecommerce_54");
          },
        },
        {
          id: "apps_ecommerce_14",
          label: "Coupons Management",
          link: ROUTES.OFFERS_COUPON,
          parentId: "apps",
          isActive: false,
          permission: "offer_and_coupon",
          click: function (e) {
            e.preventDefault();
            changeMenu("apps_ecommerce_14");
          },
        },
      ],
    },
    // {
    //   id: "apps_ecommerce_725",
    //   label: "Coupons Management",
    //   icon: " ri-coupon-line",
    //   link: "/#",
    //   isActive: false,
    //   isChildItem: true,
    //   click: function (e) {
    //     e.preventDefault();
    //     changeMenu("apps_ecommerce_725");
    //   },
    //   subItems: [],
    // },

    {
      id: "apps_ecommerce_721",
      label: "Rating Management",
      icon: "ri-star-line",
      link: "/#",
      isActive: false,
      isChildItem: true,
      click: function (e) {
        e.preventDefault();
        changeMenu("apps_ecommerce_721");
      },
      subItems: [
        {
          id: "apps_ecommerce_75",
          label: "Rating And Review",
          link: ROUTES.RATTING_REVIEW,
          parentId: "apps",
          isActive: false,
          permission: "rating_and_review",
          click: function (e) {
            e.preventDefault();
            changeMenu("apps_ecommerce_75");
          },
        },
        {
          id: "apps_ecommerce_31",
          label: "Testimonial",
          link: ROUTES.TESTIMONIAL,
          parentId: "apps",
          isActive: false,
          permission: "testimonial",
          click: function (e) {
            e.preventDefault();
            changeMenu("apps_ecommerce_31");
          },
        },
      ],
    },
    {
      id: "apps_ecommerce_731",
      label: "Style Management",
      icon: "ri-apps-2-line",
      link: "/#",
      isActive: false,
      isChildItem: true,
      click: function (e) {
        e.preventDefault();
        changeMenu("apps_ecommerce_731");
      },
      subItems: [
        {
          id: "apps_ecommerce_45",
          label: "Style Master",
          link: ROUTES.STYLE_MASTER,
          parentId: "apps",
          isActive: false,
          permission: "style",
          click: function (e) {
            e.preventDefault();
            changeMenu("apps_ecommerce_45");
          },
        },
        {
          id: "apps_ecommerce_486",
          label: "Style Collection",
          link: ROUTES.STYLE_COLLCTION,
          parentId: "apps",
          isActive: false,
          permission: "style_collection",
          click: function (e) {
            e.preventDefault();
            changeMenu("apps_ecommerce_486");
          },
        },
      ],
    },

    {
      id: "apps_ecommerce_727",
      label: "Gateway Management",
      icon: " ri-gatsby-line",
      link: "/#",
      isActive: false,
      isChildItem: true,
      click: function (e) {
        e.preventDefault();
        changeMenu("apps_ecommerce_727");
      },
      subItems: [
        {
          id: "apps_ecommerce_23",
          label: "Gateway Details",
          link: ROUTES.PAYMENT_GATEWAY_DETAILS,
          parentId: "apps",
          isActive: false,
          permission: "gateway_details",
          click: function (e) {
            e.preventDefault();
            changeMenu("apps_ecommerce_23");
          },
        },
      ],
    },
    // {
    //   id: "apps_ecommerce_728",
    //   label: "Dynamic Management",
    //   icon: " ri-list-check-2",
    //   link: "/#",
    //   isActive: false,
    //   isChildItem: true,
    //   click: function (e) {
    //     e.preventDefault();
    //     changeMenu("apps_ecommerce_728");
    //   },
    //   subItems: [
    //     {
    //       id: "apps_ecommerce_24",
    //       label: "Dynamic Menu",
    //       link: ROUTES.DYNAMIC_MENU,
    //       parentId: "apps",
    //       isActive: false,
    //       permission: "dynamic_menu",
    //       click: function (e) {
    //         e.preventDefault();
    //         changeMenu("apps_ecommerce_24");
    //       },
    //     },
    //   ],
    // },
    {
      id: "apps_ecommerce_729",
      label: "Email Management",
      icon: "ri-mail-send-line",
      link: "/#",
      isActive: false,
      isChildItem: true,
      click: function (e) {
        e.preventDefault();
        changeMenu("apps_ecommerce_729");
      },
      subItems: [
        {
          id: "apps_ecommerce_8",
          label: "Email Template",
          link: ROUTES.EMAIL_TEMPLATE,
          parentId: "apps",
          isActive: false,
          permission: "email_template",
          click: function (e) {
            e.preventDefault();
            changeMenu("apps_ecommerce_8");
          },
        },
      ],
    },
    {
      id: "apps_ecommerce_7290",
      label: "Form Management",
      icon: "ri-file-list-3-line",
      link: "/#",
      isActive: false,
      isChildItem: true,
      click: function (e) {
        e.preventDefault();
        changeMenu("apps_ecommerce_7290");
      },
      subItems: [
        {
          id: "apps_ecommerce_976",
          label: "Contact Us",
          link: ROUTES.CONTACT_US,
          parentId: "apps",
          isActive: false,
          permission: "contact",
          click: function (e) {
            e.preventDefault();
            changeMenu("apps_ecommerce_976");
          },
        },
      ],
    },

    {
      id: "apps_ecommerce_717",
      label: "Blogs Management",
      icon: "  ri-message-line",
      link: "/#",
      isActive: false,
      isChildItem: true,
      click: function (e) {
        e.preventDefault();
        changeMenu("apps_ecommerce_717");
      },
      subItems: [
        {
          id: "apps_ecommerce_12",
          label: "Blog Category",
          link: ROUTES.BLOG_CATEGORY,
          parentId: "apps",
          isActive: false,
          permission: "blog_category",
          click: function (e) {
            e.preventDefault();
            changeMenu("apps_ecommerce_12");
          },
        },
        {
          id: "apps_ecommerce_17",
          label: "Blog",
          link: ROUTES.BLOG,
          parentId: "apps",
          isActive: false,
          permission: "blog",
          click: function (e) {
            e.preventDefault();
            changeMenu("apps_ecommerce_17");
          },
        },
        {
          id: "apps_ecommerce_13",
          label: "News",
          link: ROUTES.NEWS,
          parentId: "apps",
          isActive: false,
          permission: "news",
          click: function (e) {
            e.preventDefault();
            changeMenu("apps_ecommerce_13");
          },
        },
      ],
    },
    {
      id: "apps_ecommerce_730",
      label: "FAQ Management",
      icon: " ri-questionnaire-fill",
      link: "/#",
      isActive: false,
      isChildItem: true,
      click: function (e) {
        e.preventDefault();
        changeMenu("apps_ecommerce_730");
      },
      subItems: [
        {
          id: "apps_ecommerce_9",
          label: "FAQ",
          link: ROUTES.FAQ,
          parentId: "apps",
          isActive: false,
          permission: "faq",
          click: function (e) {
            e.preventDefault();
            changeMenu("apps_ecommerce_9");
          },
        },
      ],
    },
  ]);

  const changeMenu = (menuId, field = "id", __menus = null) => {
    let _menus = [...(__menus == null ? menus : __menus)];

    _menus = _menus.map((menu) => {
      menu.isActive = menu[field] == menuId ? !menu.isActive : false;
      if (menu.hasOwnProperty("subItems")) {
        let isMainMenuActive = false;

        menu.subItems = menu.subItems.map((subItem) => {
          let isSubMenuActive = false;
          if (subItem[field] == menuId) {
            isMainMenuActive = true;
          }
          subItem.isActive =
            subItem[field] == menuId ? !subItem.isActive : false;

          if (subItem.hasOwnProperty("childItems")) {
            subItem.childItems = subItem.childItems.map((childItem) => {
              childItem.isActive =
                childItem[field] == menuId ? !childItem.isActive : false;
              if (childItem[field] == menuId) {
                isMainMenuActive = true;
                isSubMenuActive = true;
              }

              return childItem;
            });
          }

          if (isSubMenuActive) {
            subItem.isActive = isSubMenuActive;
          }

          return subItem;
        });

        if (isMainMenuActive) {
          menu.isActive = isMainMenuActive;
        }
        return menu;
      }

      return menu;
    });

    if (field == "id") {
      _menus = activateCurrentRoute(window.location.pathname, _menus);
    }

    setMenus([..._menus]);
  };

  const activateCurrentRoute = (menuId, ___menus) => {
    let _menus = [...___menus];

    _menus = _menus.map((menu) => {
      menu.isActive = menu["link"] == menuId ? true : menu.isActive;
      if (menu.hasOwnProperty("subItems")) {
        menu.subItems = menu.subItems.map((subItem) => {
          subItem.isActive =
            subItem["link"] == menuId ? true : subItem.isActive;

          if (subItem.hasOwnProperty("childItems")) {
            subItem.childItems = subItem.childItems.map((childItem) => {
              childItem.isActive =
                childItem["link"] == menuId ? true : childItem.isActive;
              return childItem;
            });
          }

          return subItem;
        });

        return menu;
      }

      return menu;
    });

    return _menus;
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const initMenu = () => {
      changeMenu(window.location.pathname, "link");
    };

    if (!HELPER.isEmpty(menus)) {
      initMenu();
    }
  }, [path]);

  const filteredMenus = menus
    .map((menu) => {
      if (menu.permission) {
        // Check if the menu has the required permission in the combinedArray
        const hasPermission = combinedArray.some(
          (permission) => permission.permission === menu.permission
        );
        // If the menu has the required permission, include it in the filtered menus
        if (hasPermission) {
          return menu;
        }
      } else if (menu.subItems) {
        // If the menu has subItems, filter them based on permissions
        const filteredSubItems = menu.subItems.filter((subItem) =>
          combinedArray.some(
            (permission) => permission.permission === subItem.permission
          )
        );
        // If there are filtered subItems, include the menu in the filtered menus
        if (filteredSubItems.length > 0) {
          menu.subItems = filteredSubItems;
          return menu;
        }
      } else {
        // If the menu has no permission and no subItems, include it in the filtered menus
        return menu;
      }
      // If the menu does not pass the permission check, return null
      return null;
    })
    .filter(Boolean);

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
  }, [history]);

  return <React.Fragment>{filteredMenus}</React.Fragment>;
};
export default Navdata;
