import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    dateRange: {}
}

export const dateSlice = createSlice({
    name: "dashboard",
    initialState: initialState,

    reducers: {
        handleFilter: (state, action) => {
            let tempState = {
                ...state,
                dateRange: {
                    ...action.payload
                }
            }
            return tempState
        },
    },
});

export default dateSlice.reducer;
export const {
    handleFilter

} = dateSlice.actions;
