import React, { lazy } from "react";
import { Navigate } from "react-router-dom";
import { ROUTES } from "../configs";
import { filterRoute } from "../configs/routes";
import Permission from "../pages/Permissions/Permission";
import UserPermission from "../pages/Permissions/UserPermission";
import PendingShipmentPage from "../pages/Ecommerce/ShippingGatewayDetails/PendingShipmentPage";
import ShippingRequestPage from "../pages/Ecommerce/ShippingGatewayDetails/ShippingRequestPage";
// import ShippingGateway from "../pages/Ecommerce/ShippingGatewayDetails/ShippingGateway";
import PaymentsPage from "../pages/Ecommerce/Orders/PaymentsPage";

// ------------------   Authentication Page  -------------------------------------------
const Login = lazy(() => import("../pages/Authentication/Login"));
const DashboardEcommerce = lazy(() => import("../pages/DashboardEcommerce"));

// ------------------  E-commerce Pages -------------------------------------------

const EcommerceCustomers = lazy(() =>
  import("../pages/Ecommerce/EcommerceCustomers/index")
);
const Starter = lazy(() =>
  import("../pages/Ecommerce/EcommerceCustomers/Starter")
);
const EmailTemplate = lazy(() =>
  import("../pages/Ecommerce/EmailTemplate/EmailTemplate")
);

// ------------------  Faq Pages -------------------------------------------

const FaqPage = lazy(() => import("../pages/Ecommerce/Faq/FaqPage"));

// ------------------  Category Pages -------------------------------------------

const CategoryPage = lazy(() => import("../pages/Ecommerce/Category/Category"));

// ------------------ Sub Category Pages -------------------------------------------

const SubCategoryPage = lazy(() =>
  import("../pages/Ecommerce/SubCategory/SubCategory")
);
// ------------------Defalut Sub Category Pages -------------------------------------------

const DefalutSubCategoryPage = lazy(() =>
  import("../pages/Ecommerce/DefalutSubCategory/DefalutSubCategoryPage")
);

// ------------------ Style Category Pages -------------------------------------------

const StyleMasterPage = lazy(() =>
  import("../pages/Ecommerce/StyleMaster/StyleMaster")
);

// ------------------ Super Category Pages -------------------------------------------

const SuperCategoryPage = lazy(() =>
  import("../pages/Ecommerce/SuperCategory/SuperCategory")
);
// ------------------ Blog Pages -------------------------------------------

const BlogPage = lazy(() => import("../pages/Ecommerce/Blog/BlogPage"));

// ------------------ Blog Category Pages -------------------------------------------

const BlogCategoryPage = lazy(() =>
  import("../pages/Ecommerce/BlogCategory/BlogCategoryPage")
);

// ------------------ News Pages -------------------------------------------

const NewsPage = lazy(() => import("../pages/Ecommerce/News/NewsPage"));

// ------------------  Coupons Pages -------------------------------------------

const OfferCouponPage = lazy(() =>
  import("../pages/Ecommerce/OfferAndCoupon/OffersCoupon")
);
// ------------------ Offer Pages -------------------------------------------

const OfferPage = lazy(() => import("../pages/Ecommerce/Offer/OfferPage"));

// ------------------ Product Page -------------------------------------------

const ProductPage = lazy(() =>
  import("../pages/Ecommerce/Products/ProductPage")
);

// ---------------------- Style Collection Page Page  -------------------------------------------
const StyleCollectionPage = lazy(() =>
  import("../pages/Ecommerce/StyleCollection/StyleCollection")
);
// ------------------ Product Details Page -------------------------------------------

const ProductDetailsPage = lazy(() =>
  import("../pages/Ecommerce/ProductDetails/ProductDetailsPage")
);

// ------------------Best Selling Product-------------------------------------
const BestSellingProductPage = lazy(() =>
  import("../pages/Ecommerce/BestSellingProduct/BestSellingProductPage")
);

// ------------------Shipping Gateway Details  Page -------------------------------------------
// const ShippingGatewayDetailsPage = lazy(() =>
//   import("../pages/Ecommerce/ShippingGatewayDetails/ShippingGatewayDetailsPage")
// );

// ------------------Shipping Gateway Details  Page -------------------------------------------
const ShippingGateways = lazy(() =>
  import("../pages/Ecommerce/ShippingGatewayDetails/ShippingGateway")
);

// ------------------ Payment Gateway Page -------------------------------------------
const PaymentGatewayPage = lazy(() =>
  import("../pages/Ecommerce/PaymentGatewayDetails/PaymentGatewayPage")
);
// ------------------ Contact Us Page -------------------------------------------

const ContactUsPage = lazy(() =>
  import("../pages/Ecommerce/ContactUs/ContactUsPage")
);

// ------------------ Dynamic Menu Page -------------------------------------------
const DynamicMenuPage = lazy(() =>
  import("../pages/Ecommerce/DynamicMenu/DynamicMenuPage")
);

// ------------------ Customer Table Page -------------------------------------------
const CustomerTablePage = lazy(() =>
  import("../pages/Ecommerce/Customer/CustomerTable")
);

// ------------------ Slider Page -------------------------------------------
const SliderPage = lazy(() => import("../pages/Ecommerce/Slider/SliderPage"));

// ------------------ Banner Page -------------------------------------------
const BannerPage = lazy(() => import("../pages/Ecommerce/Banner/BannerPage"));

// ------------------ Collection Page -------------------------------------------
const CollectionPage = lazy(() =>
  import("../pages/Ecommerce/Collection/CollectionPage")
);
// ------------------ Testimonial Page  -------------------------------------------
const TestimonialPage = lazy(() =>
  import("../pages/Ecommerce/Testimonial/TestimonialPage")
);

// ------------------ New Product Arrival Page  -------------------------------------------

const NewProductArrivalPage = lazy(() =>
  import("../pages/Ecommerce/NewProductArrival/NewProductArrivalPage")
);
// ------------------ Ratting Reviews Page  -------------------------------------------

const RattingReviewsPage = lazy(() =>
  import("../pages/Ecommerce/RattingReviewsPage/RattingReviewsPage")
);
// ------------------ Order Page  -------------------------------------------

const OrderPage = lazy(() => import("../pages/Ecommerce/Orders/OrderPage"));
const PaymentPage = lazy(() =>
  import("../pages/Ecommerce/Orders/PaymentsPage")
);

// ---------------------- Pending Order Page  -------------------------------------------
const PendingOrder = lazy(() =>
  import("../pages/Ecommerce/Orders/PendingOrder")
);

// ---------------------- Pending Order Page  -------------------------------------------
const OrderCanclePage = lazy(() =>
  import("../pages/Ecommerce/Orders/OrderCanclePage")
);

// ----------------------OrderDelivered Page -------------------------------------------
const OrderDeliveredPage = lazy(() =>
  import("../pages/Ecommerce/Orders/OrderDeliveredPage")
);

// ---------------------- Processed Order Page  -------------------------------------------
const ProcessedOrder = lazy(() =>
  import("../pages/Ecommerce/Orders/ProcessedOrder")
);

// ---------------------- Pass pending Order Page  -------------------------------------------
const PassPending = lazy(() => import("../pages/Ecommerce/Orders/PassPending"));

//----------------------------- *  authProtectedRoutes * ------------------------------
const authProtectedRoutes = [
  { path: filterRoute(ROUTES.DASHBOARD), component: <DashboardEcommerce /> },
  {
    path: "/",
    exact: true,
    component: <Navigate to={filterRoute(ROUTES.DASHBOARD)} />,
  },
  { path: "*", component: <Navigate to={filterRoute(ROUTES.DASHBOARD)} /> },

  // -----------------------------  E-commerce Pages -----------------------------

  {
    path: filterRoute(ROUTES.BEST_SELLING_PRODUCT),
    component: <BestSellingProductPage />,
  },

  {
    path: filterRoute(ROUTES.PENDING_ORDERS),
    component: <PendingOrder />,
  },
  {
    path: filterRoute(ROUTES.CANCLE_ORDERS),
    component: <OrderCanclePage />,
  },
  {
    path: filterRoute(ROUTES.DELIVERED_ORDERS),
    component: <OrderDeliveredPage />,
  },
  {
    path: filterRoute(ROUTES.PAST_PENDING_ORDERS),
    component: <PassPending />,
  },
  {
    path: filterRoute(ROUTES.PROCESSED_ORDERS),
    component: <ProcessedOrder />,
  },
  {
    path: filterRoute(ROUTES.USERS),
    component: <EcommerceCustomers />,
  },
  {
    path: filterRoute(ROUTES.PAGES_STARTER),
    component: <Starter />,
  },
  // -----------------------------  Email_Template Pages -----------------------------
  {
    path: filterRoute(ROUTES.EMAIL_TEMPLATE),
    component: <EmailTemplate />,
  },
  // -----------------------------  Contact us Pages -----------------------------
  {
    path: filterRoute(ROUTES.CONTACT_US),
    component: <ContactUsPage />,
  },

  // ----------------------------- FAQ Pages -----------------------------

  {
    path: filterRoute(ROUTES.FAQ),
    component: <FaqPage />,
  },
  // ----------------------------- Categories Pages -----------------------------

  {
    path: filterRoute(ROUTES.CATEGORY),
    component: <CategoryPage />,
  },
  // ----------------------------- Super Categories Pages -----------------------------

  {
    path: filterRoute(ROUTES.SUPER_CATEGORY),
    component: <SuperCategoryPage />,
  },
  // ----------------------------- Sub Categories Pages -----------------------------

  {
    path: filterRoute(ROUTES.SUB_CATEGORY),
    component: <SubCategoryPage />,
  },
  // -----------------------------Defalut Sub Categories Pages -----------------------------

  {
    path: filterRoute(ROUTES.DEFALUT_SUB_CATEGORY),
    component: <DefalutSubCategoryPage />,
  },
  // ----------------------------- Style Master Pages -----------------------------

  {
    path: filterRoute(ROUTES.STYLE_MASTER),
    component: <StyleMasterPage />,
  },

  // ----------------------------- Style Collection Pages -----------------------------
  {
    path: filterRoute(ROUTES.STYLE_COLLCTION),
    component: <StyleCollectionPage />,
  },

  // ----------------------------- Blog Page  -----------------------------

  {
    path: filterRoute(ROUTES.BLOG),
    component: <BlogPage />,
  },
  // ----------------------------- Blog Category Page  -----------------------------

  {
    path: filterRoute(ROUTES.BLOG_CATEGORY),
    component: <BlogCategoryPage />,
  },
  // ----------------------------- News Pages -----------------------------

  {
    path: filterRoute(ROUTES.NEWS),
    component: <NewsPage />,
  },
  // ----------------------------- Coupons Pages -----------------------------

  {
    path: filterRoute(ROUTES.OFFERS_COUPON),
    component: <OfferCouponPage />,
  },

  // ----------------------------- Offer Pages -----------------------------

  {
    path: filterRoute(ROUTES.OFFERS),
    component: <OfferPage />,
  },

  // ----------------------------- Products Pages -----------------------------

  {
    path: filterRoute(ROUTES.PRODUCTS),
    component: <ProductPage />,
  },

  {
    path: `${filterRoute(ROUTES.PRODUCTS_DETAILS)}/:id`,
    component: <ProductDetailsPage />,
  },

  // ----------------------------- Shipping Gateway Details Pages -----------------------------

  // {
  //   path: filterRoute(ROUTES.SHIPPING_GATEWAY_DETAILS),
  //   component: <ShippingGatewayDetailsPage />,
  // },

  // ----------------------------- Pending Shipment Pages -----------------------------

  {
    path: filterRoute(ROUTES.PENDING_SHIPPING_GATEWAY),
    component: <PendingShipmentPage />,
  },

  // ----------------------------- Shipping Request Pages -----------------------------

  {
    path: filterRoute(ROUTES.SHIPPING_REQUEST),
    component: <ShippingRequestPage />,
  },

  // ----------------------------- Shipping Gateway Details Pages -----------------------------

  // {
  //   path: filterRoute(ROUTES.SHIPPING_GATEWAY),
  //   component: <ShippingGateway />,
  // },

  // ----------------------------- Payment Gateway Details Pages -----------------------------

  {
    path: filterRoute(ROUTES.PAYMENT_GATEWAY_DETAILS),
    component: <PaymentGatewayPage />,
  },

  // ----------------------------- Payment Details Pages -----------------------------

  {
    path: `${filterRoute(ROUTES.PAYMENTS)}/:id`,
    component: <PaymentsPage />,
  },

  // ----------------------------- Dynamic Menu Page -----------------------------

  {
    path: filterRoute(ROUTES.DYNAMIC_MENU),
    component: <DynamicMenuPage />,
  },

  // ----------------------------- Order Page -----------------------------

  {
    path: filterRoute(ROUTES.ORDER),
    component: <OrderPage />,
  },

  // ----------------------------- Customer Page -----------------------------

  {
    path: filterRoute(ROUTES.CUSTOMER),
    component: <CustomerTablePage />,
  },

  // ----------------------------- Slider Page -----------------------------

  {
    path: filterRoute(ROUTES.SLIDER),
    component: <SliderPage />,
  },

  // ----------------------------- Banner Page -----------------------------

  {
    path: filterRoute(ROUTES.BANNER),
    component: <BannerPage />,
  },
  // ----------------------------- Collection Page -----------------------------

  {
    path: filterRoute(ROUTES.COLLECTION),
    component: <CollectionPage />,
  },
  // ----------------------------- Testimonial Page -----------------------------

  {
    path: filterRoute(ROUTES.TESTIMONIAL),
    component: <TestimonialPage />,
  },

  // ----------------------------- Product Arrival Page -----------------------------

  {
    path: filterRoute(ROUTES.PRODUCT_ARRIVAL),
    component: <NewProductArrivalPage />,
  },
  // ----------------------------- Ratting Review Page -----------------------------

  {
    path: filterRoute(ROUTES.RATTING_REVIEW),
    component: <RattingReviewsPage />,
  },

  // -----------------------------  Profile Pages -----------------------------

  {
    path: filterRoute(ROUTES.PERMISSION),
    component: <Permission />,
  },
  {
    path: filterRoute(ROUTES.USER_PERMISSION),
    component: <UserPermission />,
  },
];

//----------------------------- *  publicRoutes * ------------------------------
const publicRoutes = [
  // Authentication Page
  { path: filterRoute(ROUTES.LOGIN), component: <Login /> },
];

export { authProtectedRoutes, publicRoutes };
