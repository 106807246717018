const defaultState = {
    toolkit: {
        auth: {
            token: null,
            user: null,
        }
    }
}


export default defaultState;