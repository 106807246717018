import React, { useState, useEffect, useMemo } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Input,
  CardHeader,
  UncontrolledTooltip,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import PaginationTable, {
  usePaginationTable,
} from "../../../Components/UI/Pagination/PaginationTable";
import { API, HELPER } from "../../../services";
import { apiConfig, appConfig } from "../../../configs";
import useDidMountEffect from "../../../Components/Hooks/useDidMountEffect";
import { toaster } from "../../../services/helper";
import momentTimezone from "moment-timezone";
import error400cover from "../../../assets/images/no-data-found-page.png";
import Flatpickr from "react-flatpickr";
import ReactButton from "../../../Components/Common/ReactButton";
import ReactSelect from "../../../Components/Common/ReactSelect";
import Swal from "sweetalert2";
import moment from "moment-timezone";

const ShippingRequestPage = () => {
  const [dateRange, setDateRange] = useState([
    new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
  ]);
  const [selectedOption, setSelectedOption] = useState("");
  const [resetReactSelect, setResetReactSelect] = useState(false);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

  const COLUMNS = [
    { title: "Way Bill" },
    { title: "Shipping Date" },
    { title: "Status" },
    { title: "Logistic Name" },
    { title: "Refnum" },
    { title: "Is Canceled" },
    { title: "Order No" },
    { title: "Order Date" },
    { title: "Total Amount" },
    { title: "Net Amount" },
    { title: "Discount Amount" },
    { title: "Shipping Charges" },
    { title: "Order Status" },
    { title: "Payment Method" },
    { title: "ReMark" },
    { title: "Tracking Url" },
    { title: "Order Confirmed" },
    { title: "Invoice Generated" },
    { title: "Order Canceled" },
    { title: "Order Return" },
    { title: "Payment Status" },
    { title: "Is Share With Friends" },
    { title: "Friends Discount" },
    { title: "Billing Customer Name" },
    { title: "Billing Last Name" },
    { title: "Billing Email" },
    { title: "Billing Mobile No." },
    { title: "Billing Address Type" },
    { title: "Billing Address" },
    { title: "Billing Address 2" },
    { title: "Billing Country" },
    { title: "Billing State" },
    { title: "Billing City" },
    { title: "Billing Pincode" },
    { title: "Shipping Is Billing" },
    { title: "Shipping Customer Name" },
    { title: "Shipping Last Name" },
    { title: "Shipping Email" },
    { title: "Shipping Mobile No." },
    { title: "Shipping Address" },
    { title: "Shipping Address 2" },
    { title: "Shipping Country" },
    { title: "Shipping State" },
    { title: "Shipping City" },
    { title: "Shipping Pincode" },
    { title: "Is Default" },
  ];

  const { state, setState, changeState, ...otherTableActionProps } =
    usePaginationTable({
      searchTxt: "",
      date: {
        from_date: "",
        to_date: "",
      },

      is_clear: false,
    });

  const paginate = (clear = false, isNewFilter = false, type = null) => {
    changeState("loader", true);
    let clearStates = {
      searchTxt: "",
      date: {
        fromDate: "",
        toDate: "",
      },

      ...appConfig.default_pagination_state,
    };
    let filter = {
      page: clear ? clearStates.page : state.page,
      searchTxt: clear ? clearStates.searchTxt : state.searchTxt,
      from_date:
        !clear && dateRange[0]
          ? momentTimezone
              .tz(
                dateRange[0],
                Intl.DateTimeFormat().resolvedOptions().timeZone
              )
              .format(appConfig.dateDisplayEditFormat)
          : momentTimezone
              .tz(
                new Date(new Date().getFullYear(), new Date().getMonth(), 1),
                Intl.DateTimeFormat().resolvedOptions().timeZone
              )
              .format(appConfig.dateDisplayEditFormat),

      to_date:
        !clear && dateRange[1]
          ? momentTimezone
              .tz(
                dateRange[1],
                Intl.DateTimeFormat().resolvedOptions().timeZone
              )
              .format(appConfig.dateDisplayEditFormat)
          : momentTimezone
              .tz(
                new Date(
                  new Date().getFullYear(),
                  new Date().getMonth() + 1,
                  0
                ),
                Intl.DateTimeFormat().resolvedOptions().timeZone
              )
              .format(appConfig.dateDisplayEditFormat),
      rowsPerPage: state.rowsPerPage,
    };

    let newFilterState = { ...appConfig.default_pagination_state };

    if (clear) {
      filter = _.merge(filter, clearStates);
      setDateRange([
        new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
      ]);
    } else if (isNewFilter) {
      filter = _.merge(filter, newFilterState);
    }
    //-------- Get Order API call----------------
    API.get(apiConfig.getNewShippingRequest, filter)
      .then((res) => {
        setState({
          ...state,
          total_items: res.count,
          data: res.rows,
          // data: res.rows,
          ...(clear && clearStates),
          ...(isNewFilter && newFilterState),
          loader: false,
          is_clear: clear == true,
        });
      })
      .catch((err) => {
        if (
          err.status == 400 ||
          err.status == 401 ||
          err.status == 409 ||
          err.status == 403
        ) {
          toaster.error(err.errors.message);
        } else {
          console.error(err);
        }
        setState({
          ...state,
          ...(clear && clearStates),
          ...(isNewFilter && newFilterState),
          loader: false,
          is_clear: clear == true,
        });
      });
  };
  // ------------CustomCheckbox----------
  const CustomCheckbox = ({ id, checked, onChange }) => (
    <div className="main-input-padding-div">
      <Input
        type="checkbox"
        defaultChecked={checked}
        onChange={onChange}
        id={id}
      />
    </div>
  );
  // -------handleSelectChange---------------
  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const sortOptions = [
    { label: "manifest", value: "manifest" },
    { label: "label", value: "label" },
    { label: "invoice", value: "invoice" },
  ];
  // ---------------handleSelectAll-----------------
  const handleSelectAll = () => {
    const selectAllChecked = selectedCheckboxes.length === state.data.length;
    const updatedCheckboxes = selectAllChecked
      ? []
      : state.data.map((item) => item.system_order_id);
    setSelectedCheckboxes(updatedCheckboxes);
  };
  // ----------------handleCheckbox--------------
  const handleCheckbox = (item) => {
    const isChecked = selectedCheckboxes.some(
      (selectedItem) => selectedItem === item.system_order_id
    );

    let updatedCheckboxes;

    if (isChecked) {
      updatedCheckboxes = selectedCheckboxes.filter(
        (selectedItem) => selectedItem !== item.system_order_id
      );
    } else {
      updatedCheckboxes = [...selectedCheckboxes, item.system_order_id];
    }
    setSelectedCheckboxes(updatedCheckboxes);
  };
  // ---------------selectAllCheckbox--------------
  const selectAllCheckbox = (
    <div className="main-input-padding-div">
      <Input
        type="checkbox"
        checked={selectedCheckboxes.length === state.data.length}
        onChange={handleSelectAll}
      />
    </div>
  );
  // -------------handleDownload-----------------------
  const handleDownload = () => {
    if (selectedOption) {
      API.post(apiConfig.printMaterial.replace(":material", selectedOption), {
        order_ids: selectedCheckboxes,
      })
        .then((res) => {
          switch (selectedOption) {
            case "manifest":
              const manifestUrl = res.file_name;
              window.open(manifestUrl, "_blank");
              break;
            case "label":
              const labelUrl = res.file_name;
              window.open(labelUrl, "_blank");
              break;
            case "invoice":
              const invoiceUrl = res.invoice_url;
              window.open(invoiceUrl, "_blank");
              break;
            default:
              break;
          }
        })
        .catch((err) => {
          if (err.status == 422) {
            toaster.error(err.errors.order_ids);
          } else {
            console.error(err);
          }
        });
    }
  };

  const handleCancelShipping = () => {
    Swal.fire({
      title: "Are You Sure",
      text: "Are you sure you want to Cancel this Shipping Request ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "green",
      cancelButtonColor: "red",
      cancelButtonText: "No",
      confirmButtonText: "Yes",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        API.post(apiConfig.cancelShipping, {
          order_ids: selectedCheckboxes,
        })
          .then((res) => {
            HELPER.toaster.success("Shipping Request Cancelled Successfully");
            setSelectedCheckboxes([]);
            paginate();
          })
          .catch((err) => {
            if (
              err.status == 400 ||
              err.status == 401 ||
              err.status == 409 ||
              err.status == 403 ||
              err.status == 500 ||
              err.status == 501
            ) {
              toaster.error(err.errors.message);
            } else if (err.status == 422) {
              toaster.error(err.errors.order_ids);
            } else {
              toaster.error(err);
            }
          });
      }
    });
  };
  useEffect(() => {
    paginate();
  }, []);

  useDidMountEffect(() => {
    if (state.is_clear == false) {
      paginate();
    } else {
      changeState("is_clear", false);
    }
    setSelectedCheckboxes([]);
  }, [state.page, state.rowsPerPage]);

  // -------------Status Display-----------
  const getStatusClassName = (status) => {
    switch (status) {
      case "SUCCESS":
        return "badge text-uppercase bg-success-subtle text-success";
      case "INITIATED":
        return "badge text-uppercase bg-info-subtle text-info";
      case "REFUND":
        return "badge text-uppercase bg-danger-subtle text-danger";
      case "PENDING":
        return "badge text-uppercase bg-warning-subtle text-warning";
      case "FAILED":
        return "badge text-uppercase bg-danger-subtle text-danger";
      case "NOT_CONFIRMED":
        return "badge text-uppercase bg-danger-subtle text-danger";
      default:
    }
  };
  // -----------Order Status--------------
  const getOrderStatus = (order_status) => {
    switch (order_status) {
      case "Delivered":
        return "badge text-uppercase bg-success-subtle text-success";
      case "confirmed":
        return "badge text-uppercase bg-info-subtle text-info";
      case "pending":
        return "badge text-uppercase bg-warning-subtle text-warning";
      case "canceled":
        return "badge text-uppercase bg-danger-subtle text-danger";
      default:
    }
  };
  const rows = useMemo(() => {
    return state.data.map((item) => {
      return {
        item: item,
        columns: [
          <CustomCheckbox
            id={`checkbox_${item.system_order_id}`}
            checked={selectedCheckboxes.some(
              (selectedItem) => selectedItem === item.system_order_id
            )}
            onChange={(e) => handleCheckbox(item)}
          />,
          <span>{item.waybill}</span>,
          <span>{item.shipping_date}</span>,
          <span>{item.status}</span>,
          <span>{item.logistic_name}</span>,
          <span>{item.refnum}</span>,
          <span>
            {item.is_canceled == true ? (
              <span className="badge text-uppercase bg-success-subtle text-success">
                True
              </span>
            ) : (
              <span className="badge text-uppercase bg-danger-subtle text-danger">
                False
              </span>
            )}
          </span>,
          <span>{item?.Order?.order_no}</span>,
          <span>
            {moment(item?.Order?.order_date).format(
              appConfig.dateDisplayEditFormat
            )}
          </span>,
          <span>{item?.Order?.total_amount}</span>,
          <span>{item?.Order?.net_amount}</span>,
          <span>{item?.Order?.discount_amount}</span>,
          <span>{item?.Order?.shipping_charges}</span>,
          <span className={getOrderStatus(item?.Order?.order_status)}>
            {item?.Order?.order_status}
          </span>,
          <span>{item?.Order?.payment_method}</span>,
          <span>{item.remark}</span>,
          <span>{item.tracking_url}</span>,
          <span>
            {item?.Order?.order_confirmed == true ? (
              <span className="badge text-uppercase bg-success-subtle text-success">
                True
              </span>
            ) : (
              <span className="badge text-uppercase bg-danger-subtle text-danger">
                False
              </span>
            )}
          </span>,
          <span>
            {item?.Order?.invoice_generated == true ? (
              <span className="badge text-uppercase bg-success-subtle text-success">
                True
              </span>
            ) : (
              <span className="badge text-uppercase bg-danger-subtle text-danger">
                False
              </span>
            )}
          </span>,
          <span>
            {item?.Order?.order_canceled == true ? (
              <span className="badge text-uppercase bg-success-subtle text-success">
                True
              </span>
            ) : (
              <span className="badge text-uppercase bg-danger-subtle text-danger">
                False
              </span>
            )}
          </span>,
          <span>
            {item?.Order?.order_return == true ? (
              <span className="badge text-uppercase bg-success-subtle text-success">
                True
              </span>
            ) : (
              <span className="badge text-uppercase bg-danger-subtle text-danger">
                False
              </span>
            )}
          </span>,
          <span className={getStatusClassName(item?.Order?.payment_status)}>
            {item?.Order?.payment_status}
          </span>,
          <span>
            {item?.Order?.is_share_with_friends == true ? (
              <span className="badge text-uppercase bg-success-subtle text-success">
                True
              </span>
            ) : (
              <span className="badge text-uppercase bg-danger-subtle text-danger">
                False
              </span>
            )}
          </span>,
          <span>{item?.Order?.is_share_with_friends_discount}</span>,
          <span>{item?.Order?.CustomerAddress?.billing_customer_name}</span>,
          <span>{item?.Order?.CustomerAddress?.billing_last_name}</span>,
          <span>{item?.Order?.CustomerAddress?.billing_email}</span>,
          <span>{item?.Order?.CustomerAddress?.billing_phone}</span>,
          <span>{item?.Order?.CustomerAddress?.billing_address_type}</span>,
          <span>{item?.Order?.CustomerAddress?.billing_address}</span>,
          <span>{item?.Order?.CustomerAddress?.billing_address_2}</span>,
          <span>{item?.Order?.CustomerAddress?.billing_country}</span>,
          <span>{item?.Order?.CustomerAddress?.billing_state}</span>,
          <span>{item?.Order?.CustomerAddress?.billing_city}</span>,
          <span>{item?.Order?.CustomerAddress?.billing_pincode}</span>,
          <span>
            {item?.Order?.CustomerAddress?.shipping_is_billing == true ? (
              <span className="badge text-uppercase bg-success-subtle text-success">
                True
              </span>
            ) : (
              <span className="badge text-uppercase bg-danger-subtle text-danger">
                False
              </span>
            )}
          </span>,
          <span>{item?.Order?.CustomerAddress?.shipping_customer_name}</span>,
          <span>{item?.Order?.CustomerAddress?.shipping_last_name}</span>,
          <span>{item?.Order?.CustomerAddress?.shipping_email}</span>,
          <span>{item?.Order?.CustomerAddress?.shipping_phone}</span>,
          <span>{item?.Order?.CustomerAddress?.shipping_address}</span>,
          <span>{item?.Order?.CustomerAddress?.shipping_address_2}</span>,
          <span>{item?.Order?.CustomerAddress?.shipping_country}</span>,
          <span>{item?.Order?.CustomerAddress?.shipping_state}</span>,
          <span>{item?.Order?.CustomerAddress?.shipping_city}</span>,
          <span>{item?.Order?.CustomerAddress?.shipping_pincode}</span>,
          <span>
            {item?.Order?.CustomerAddress?.is_default == true ? (
              <span className="badge text-uppercase bg-success-subtle text-success">
                True
              </span>
            ) : (
              <span className="badge text-uppercase bg-danger-subtle text-danger">
                False
              </span>
            )}
          </span>,
        ],
      };
    });
  }, [state.data, selectedCheckboxes]);

  document.title = " Shipping Request Page List";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title=" Shipping Request"
            pageTitle="Shipping Management"
          />
          <Row>
            <Col lg={12}>
              <Card id="customerList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">
                          Shipping Request List
                        </h5>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <div className="card-body pt-0 ">
                  <div className="main-link-up-modal-div">
                    <PaginationTable
                      header={COLUMNS}
                      rows={rows}
                      totalItems={state.total_items}
                      perPage={state.rowsPerPage}
                      activePage={state.page}
                      checkboxColumn={false}
                      selectedRows={state.selectedRows}
                      selectedCheckboxes={selectedCheckboxes}
                      selectAllCheckbox={selectAllCheckbox}
                      enableOrder={true}
                      isLoader={state.loader}
                      emptyTableImg={<img src={error400cover} width="350px" />}
                      {...otherTableActionProps}
                    >
                      <div>
                        <Row
                          className="pt-3 pb-3"
                          style={{
                            borderTop: "1px dashed rgb(152 166 173 / 50%)",
                            borderBottom: "1px dashed rgb(152 166 173 / 50%)",
                          }}
                        >
                          <Col xl={4}>
                            <div>
                              <Flatpickr
                                className="form-control"
                                placeholder="Select Date Range"
                                onChange={(date) => setDateRange(date)}
                                value={dateRange}
                                options={{
                                  mode: "range",
                                  dateFormat: "Y-m-d",
                                }}
                              />
                            </div>
                          </Col>
                          <Col sm={8} style={{ paddingLeft: "0px" }}>
                            <div className="d-flex justify-content-between">
                              <div className="d-flex">
                                <div className="d-flex align-items-baseline shipping-request-div">
                                  <div
                                    style={{
                                      width: "250px",
                                      marginRight: "10px",
                                    }}
                                  >
                                    <ReactSelect
                                      key={
                                        resetReactSelect
                                          ? "rerender"
                                          : "no-rerender"
                                      }
                                      placeholder="Select Document "
                                      options={sortOptions}
                                      value={selectedOption || ""}
                                      onChange={handleSelectChange}
                                      isDisabled={
                                        selectedCheckboxes.length === 0
                                      }
                                    />
                                  </div>
                                  <ReactButton
                                    color="success"
                                    className="btn btn-primary "
                                    type="submit"
                                    onClick={handleDownload}
                                    disabled={selectedCheckboxes.length === 0}
                                  >
                                    Download
                                  </ReactButton>
                                </div>
                                <div style={{ marginLeft: "10px" }}>
                                  <button
                                    type="button"
                                    className="btn btn-primary "
                                    onClick={() => paginate(false, true)}
                                  >
                                    <i className="ri-search-line me-2 align-bottom"></i>
                                    Search
                                  </button>
                                </div>
                                <div>
                                  <button
                                    id="tooltipTop13"
                                    type="button"
                                    className="btn btn-danger  mx-2"
                                    onClick={() => {
                                      paginate(true);
                                      setResetReactSelect(!resetReactSelect);
                                      setSelectedOption("");
                                      setSelectedCheckboxes([]);
                                    }}
                                  >
                                    <i className="ri-restart-line align-bottom"></i>
                                    <UncontrolledTooltip
                                      placement="top"
                                      target="tooltipTop13"
                                    >
                                      Reset
                                    </UncontrolledTooltip>
                                  </button>
                                </div>
                              </div>
                              <div className="float-end">
                                <ReactButton
                                  color="danger"
                                  className="btn btn-danger"
                                  type="submit"
                                  onClick={handleCancelShipping}
                                  disabled={selectedCheckboxes.length === 0}
                                >
                                  Cancel Shipping
                                </ReactButton>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </PaginationTable>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ShippingRequestPage;
