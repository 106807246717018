import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  Col,
  Form,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import { API } from "../../services";
import { apiConfig } from "../../configs";
import ReactButton from "../../Components/Common/ReactButton";
import Validators from "../../Components/validations/Validator";
import { toaster } from "../../services/helper";
import error400cover from "../../assets/images/no-data-found-page.png";

function AddUserPermission({ modal, toggle, setModel, userId, callBack }) {
  const [getUserPermission, setGetUserPermission] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState({});
  const [isLoader, setIsLoader] = useState(false);

  // ------------Get NotPermission of User Api-----------------------
  const getNotUserPermissions = () => {
    API.get(apiConfig.getUserNotPermission.replace(":user_id", userId))
      .then((res) => {
        const formattedPermissions = formatPermissions(res.permissionData);
        setGetUserPermission(formattedPermissions || {});
      })
      .catch(console.error);
  };

  const formatPermissions = (permissions) => {
    const formattedPermissions = {};

    Object.keys(permissions).forEach((category) => {
      formattedPermissions[category] = permissions[category].map(
        (permission) => ({
          ...permission,
        })
      );
    });

    return formattedPermissions;
  };
  useEffect(() => {
    getNotUserPermissions();
  }, []);
  // -------------handleCheckboxChange------------------
  const handleCheckboxChange = (
    item,
    permission,
    checked,
    field,
    permissionID
  ) => {
    setSelectedPermissions((prevState) => {
      let permissionObj = {};
      let updatedPermissions = { ...prevState };
      if (!updatedPermissions[item]) {
        updatedPermissions[item] = [];
      }
      let existingPermission = updatedPermissions[item].find(
        (permission) => permission.permission_id === permissionID
      );
      if (existingPermission) {
        if (checked === "all") {
          existingPermission.view = field;
          existingPermission.create = field;
          existingPermission.edit = field;
          existingPermission.delete = field;
        } else {
          existingPermission[checked] = field;
          const hasChecked =
            existingPermission.create ||
            existingPermission.edit ||
            existingPermission.delete;
          existingPermission.view = hasChecked
            ? true
            : (existingPermission[checked] = field);
        }
        permissionObj = existingPermission;
      } else {
        let newPermission = {
          permission_id: permissionID,
          view: false,
          create: false,
          edit: false,
          delete: false,
        };
        if (checked === "all") {
          newPermission.view = field;
          newPermission.create = field;
          newPermission.edit = field;
          newPermission.delete = field;
        } else {
          newPermission[checked] = field;
          const hasChecked =
            newPermission.create || newPermission.edit || newPermission.delete;
          newPermission.view = hasChecked
            ? true
            : (newPermission[checked] = field);
        }
        updatedPermissions[item].push(newPermission);
        permissionObj = newPermission;
      }

      setGetUserPermission((prev) => ({
        ...prev,
        [item]: prev[item].map((_item) => {
          if (_item.permission_id == permissionObj.permission_id) {
            return {
              ..._item,
              ...permissionObj,
            };
          }
          return _item;
        }),
      }));

      // Check if all checkboxes for the permission are unchecked
      const allUnchecked =
        !permissionObj.view &&
        !permissionObj.create &&
        !permissionObj.edit &&
        !permissionObj.delete;

      // If all checkboxes are unchecked, remove the permission from selectedPermissions
      if (allUnchecked) {
        updatedPermissions[item] = updatedPermissions[item].filter(
          (perm) => perm.permission_id !== permissionID
        );
      }

      return updatedPermissions;
    });
  };
  // ----------------Handle onSubmit Add User Permission------------------

  const handleSubmit = (e) => {
    setIsLoader(true);

    // Check if at least one permission is selected
    const hasSelectedPermission = Object.values(selectedPermissions)
      .flat()
      .every(
        (permission) =>
          permission.view ||
          permission.create ||
          permission.edit ||
          permission.delete
      );

    if (!hasSelectedPermission) {
      toaster.error("Please select at least one permission");
      setIsLoader(false);
      return;
    }

    const payload = {
      user_id: userId,
      permissionDetails: Object.values(selectedPermissions).flat(),
    };

    API.post(apiConfig.addUserPermission, payload)
      .then((res) => {
        toaster.success("Add Permission successfully");
        setModel(false);
        callBack();
      })
      .catch((err) => {
        if (err.status == 400 || err.status == 401) {
          toaster.error(err.errors.message);
        } else if (err.status == 422) {
          toaster.error(err.errors.permissionDetails[0]);
        } else {
          toaster.error(err.errors.message);
        }
      })
      .finally(() => {
        setIsLoader(false);
      });
  };

  return (
    // --------Add User Permission modal----------------------
    <Modal
      id="showModal"
      size="xl"
      isOpen={modal}
      toggle={toggle}
      backdrop={"static"}
      centered
    >
      <ModalHeader className="bg-light p-3" toggle={toggle}>
        Add User Permission
      </ModalHeader>
      <ModalBody style={{ background: "rgb(213 213 228 / 33%)" }}>
        <div>
          <Validators>
            {({ onSubmit, errors }) => {
              return (
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    onSubmit(handleSubmit);
                  }}
                  id="showModal"
                >
                  <Col
                    lg={12}
                    style={{ height: "calc(100vh - 300px)", overflow: "auto" }}
                  >
                    {Object.keys(getUserPermission).length == 0 && (
                      <div className="d-flex align-items-baseline justify-content-center mt-5">
                        {<img src={error400cover} width="500px" />}
                      </div>
                    )}
                    {Object.keys(getUserPermission).length > 0 && (
                      <div>
                        {Object.keys(getUserPermission).map((key) => (
                          <div key={key}>
                            <Card>
                              <CardHeader className="border-0 pb-2">
                                <Row className="g-4 align-items-center">
                                  <div className="col-sm">
                                    <h4>{key}</h4>
                                  </div>
                                </Row>
                              </CardHeader>
                              <div className="card-body pt-0 px-2 pb-0">
                                <Table>
                                  <thead>
                                    <tr style={{ background: "#F3F3F9" }}>
                                      <th>All</th>
                                      <th>Permission Name</th>
                                      <th>View</th>
                                      <th>Create</th>
                                      <th>Edit</th>
                                      <th>Delete</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {Object.keys(getUserPermission[key]).map(
                                      (item) => (
                                        <tr key={item}>
                                          <td>
                                            <Input
                                              type="checkbox"
                                              checked={
                                                getUserPermission[key][item]
                                                  .create &&
                                                getUserPermission[key][item]
                                                  .edit &&
                                                getUserPermission[key][item]
                                                  .view &&
                                                getUserPermission[key][item]
                                                  .delete
                                              }
                                              onChange={(e) =>
                                                handleCheckboxChange(
                                                  key,
                                                  item,
                                                  "all",
                                                  e.target.checked,
                                                  getUserPermission[key][item]
                                                    .permission_id
                                                )
                                              }
                                            />
                                          </td>
                                          <td style={{ width: "350px" }}>
                                            <h6>
                                              {
                                                getUserPermission[key][item]
                                                  .permission_name
                                              }
                                            </h6>
                                          </td>

                                          <td>
                                            <Input
                                              type="checkbox"
                                              checked={
                                                getUserPermission[key][item]
                                                  .view
                                              }
                                              onChange={(e) =>
                                                handleCheckboxChange(
                                                  key,
                                                  item,
                                                  "view",
                                                  e.target.checked,
                                                  getUserPermission[key][item]
                                                    .permission_id
                                                )
                                              }
                                            />
                                          </td>
                                          <td>
                                            <Input
                                              type="checkbox"
                                              checked={
                                                getUserPermission[key][item]
                                                  .create
                                              }
                                              onChange={(e) =>
                                                handleCheckboxChange(
                                                  key,
                                                  item,
                                                  "create",
                                                  e.target.checked,
                                                  getUserPermission[key][item]
                                                    .permission_id
                                                )
                                              }
                                            />
                                          </td>
                                          <td>
                                            <Input
                                              type="checkbox"
                                              checked={
                                                getUserPermission[key][item]
                                                  .edit
                                              }
                                              onChange={(e) =>
                                                handleCheckboxChange(
                                                  key,
                                                  item,
                                                  "edit",
                                                  e.target.checked,
                                                  getUserPermission[key][item]
                                                    .permission_id
                                                )
                                              }
                                            />
                                          </td>
                                          <td>
                                            <Input
                                              type="checkbox"
                                              checked={
                                                getUserPermission[key]?.[item]
                                                  ?.delete
                                              }
                                              onChange={(e) =>
                                                handleCheckboxChange(
                                                  key,
                                                  item,
                                                  "delete",
                                                  e.target.checked,
                                                  getUserPermission[key][item]
                                                    .permission_id
                                                )
                                              }
                                            />
                                          </td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </Table>
                              </div>
                            </Card>
                          </div>
                        ))}
                      </div>
                    )}
                  </Col>
                  <div className="mt-4 w-100 d-inline-flex justify-content-end">
                    <ReactButton
                      loader={isLoader}
                      color="success"
                      className="btn btn-success w-25"
                      type="submit"
                    >
                      Add Permission
                    </ReactButton>
                  </div>
                </Form>
              );
            }}
          </Validators>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default AddUserPermission;
